import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";

const PurchaseWbtc = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const UsdtAdress=Environment.wbtc
  const priceUsdt=10000
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);
  const purchaseWbtc = useCallback(
    async (amount, postApiRes,id,balance,min) => {
      amount =parseFloat(amount).toFixed(6)
      let amount1 = Math.round((amount) * 1e12) / 1e12;
      let nd = amount1*10**8

      let minvalue=min*0.99
      minvalue= parseFloat(minvalue).toFixed(6)
      minvalue=Math.round((minvalue) * 1e12) / 1e12;
      // console.log(minvalue,'minvalue');
      minvalue= web3.utils.toWei(minvalue.toString(), "ether");

      // console.log(postApiRes?.factorNormalization,UsdtAdress,priceUsdt.toString(),minvalue,postApiRes.code,id,postApiRes.deadline, postApiRes?.v, postApiRes?.r, postApiRes?.s,' nd,postApiRes.code,id,postApiRes.deadline,postApiRes?.v, postApiRes?.r, postApiRes?.s');
  //     let tuple = {
  //       postApiRes.code:postApiRes.postApiRes.code,
  //       GodAgentPercentage:postApiRes.gotAgentPercentage,
  //       MegaAgentPercentage:postApiRes.megaAgentPercentage,
  //       SuperAgentPercentage:postApiRes.superAgentPercentage,
  //       AgentPercentage:postApiRes.agentPercentage,
  //       GodAgentAddress:postApiRes.gotAgentAddress,
  //       MegaAgentAddress:postApiRes.megaAgentAddress,
  //       SuperAgentAddress:postApiRes.superAgentAddress,
  //       AgentAddress:postApiRes.agentAddress,
  // };
  let gas
      try {
        let gasPrice=await web3.eth.getGasPrice()
        gasPrice = parseInt(gasPrice) + 3000000000
       gas = await contract.methods
          .purchaseTokenWithToken(
            UsdtAdress,postApiRes?.normalizationFactor,postApiRes?.price, nd.toString(),minvalue,postApiRes.code,id,postApiRes.deadline,postApiRes?.v, postApiRes?.r, postApiRes?.s
          )
          .estimateGas({
            from: account,
            gasPrice
          });
        const details = await contract.methods
          .purchaseTokenWithToken(
            UsdtAdress,postApiRes?.normalizationFactor,postApiRes?.price, nd.toString(),minvalue,postApiRes.code,id,postApiRes.deadline,postApiRes?.v, postApiRes?.r, postApiRes?.s
          )
          .send({
            from: account,
            gas:gas,
            gasPrice
          });
        return details;
      } catch (error) {

        // let gasPrice1=await web3.eth.getGasPrice()
        // let gasPrice = parseInt(gasPrice1) + 3000000000
        // gas=gas*gasPrice
        // let nd = web3.utils.fromWei(gas.toString(), "ether");
        // console.log(gas,nd,balance );
        // if(nd>balance){
        //    toast.error("Insufficient ETH for Transaction")
        // }else{
          throw error;
        // }
 
      }
    },
    [contract, account,web3]
  );
  return { purchaseWbtc: purchaseWbtc };
};
export default PurchaseWbtc;
