import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { NftPurchaseContract,chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";

const PurchaseNFTwithLink = () => {
  // const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);
  const UsdtAdress=Environment.link
  const priceUsdt=10000
  const purchaseNFTwithLink = useCallback(
    async ( postApiRes,currentRoundd,nftPriceArry,price,nftPriceArry1,balance,indexx) => {
      // console.log(postApiRes,currentRoundd,nftPriceArry,price,nftPriceArry1,balance,indexx,'postApiRes,currentRoundd,nftPriceArry,price,nftPriceArry1,balance,indexx');
    // let amount11 = parseFloat(amount).toFixed(6)
    //   let amount1 = Math.round((amount11) * 1e12) / 1e12;
    //   let nd = web3.utils.toWei(amount1.toString(), "ether");
    //   let minvalue=min*0.99
    //   minvalue= parseFloat(minvalue).toFixed(6)
    //   minvalue=Math.round((minvalue) * 1e12) / 1e12;
    //   minvalue= web3.utils.toWei(minvalue.toString(), "ether");
 
      // let tuple = {
      // code:postApiRes.code,
      // GodAgentPercentage:postApiRes.gotAgentPercentage,
      // MegaAgentPercentage:postApiRes.megaAgentPercentage,
      // SuperAgentPercentage:postApiRes.superAgentPercentage,
      // AgentPercentage:postApiRes.agentPercentage,
      // GodAgentAddress:postApiRes.gotAgentAddress,
      // MegaAgentAddress:postApiRes.megaAgentAddress,
      // SuperAgentAddress:postApiRes.superAgentAddress,
      // AgentAddress:postApiRes.agentAddress
      // console.log(nftPriceArry1,'nftPriceArry1');
      nftPriceArry1=nftPriceArry1/price
      nftPriceArry1=parseFloat(nftPriceArry1).toFixed(6)
      // console.log(nftPriceArry1,'nftPriceArry1');
      nftPriceArry1=web3.utils.toWei(nftPriceArry1.toString(), "mwei");
      // console.log(nftPriceArry1,'nftPriceArry1');
    //   b=b*10**18

      // };
      // console.log(postApiRes?.code,id,postApiRes.deadline,minvalue, postApiRes?.v, postApiRes?.r, postApiRes?.s,'nooor');
      let gas
      try {
        let gasPrice = await web3.eth.getGasPrice()
        gasPrice = parseInt(gasPrice) + 3000000000
         gas = await contract.methods
          .purchaseNFTWithToken(UsdtAdress,postApiRes?.price,postApiRes?.normalizationFactor,postApiRes.code,currentRoundd,nftPriceArry,postApiRes.deadline,indexx,postApiRes?.v, postApiRes?.r, postApiRes?.s)
          .estimateGas({
            from: account,
            gasPrice
          });
        const details = await contract.methods
          .purchaseNFTWithToken(UsdtAdress,postApiRes?.price,postApiRes?.normalizationFactor,postApiRes.code,currentRoundd,nftPriceArry,postApiRes.deadline,indexx,postApiRes?.v, postApiRes?.r, postApiRes?.s)
          .send({
            from: account,
            gas: gas,
            gasPrice
          });
        return details;
      } catch (error) {
        // let gasPrice1=await web3.eth.getGasPrice()
        // let gasPrice = parseInt(gasPrice1) + 3000000000
        // gas=gas*gasPrice
        // let nd = web3.utils.fromWei(gas.toString(), "ether");
        // console.log(gas,nd,balance );
        // if(nd>balance){
        //    toast.error("Insufficient ETH for Transaction")
        // }else{
          throw error;
        // }
      }
    },
    [contract, account,web3]
  );
  return { purchaseNFTwithLink: purchaseNFTwithLink };
};
export default PurchaseNFTwithLink;
