import React, { useEffect } from 'react'
import "./dopnft.scss"
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NftPrice from '../../hooks/chinaFunction/nftprice';
import { event } from 'jquery';
import { useWeb3React } from "@web3-react/core";
import { API_URL } from "../../utils/ApiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import ApproveNft from '../../hooks/dataFetchers/ApproveNft';
import PurchaseNFTwithEth from '../../hooks/chinaFunction/purchaseNFTwithEth';
import PurchaseNFTwithUsdt from '../../hooks/chinaFunction/purchaseNFTwithUsdt';
import PurchaseNFTwithUsdc from '../../hooks/chinaFunction/puchaseNFTwithUsdc';
import PurchaseNFTwithWbtc from '../../hooks/chinaFunction/purchaseNFTwithWbtc';
import PurchaseNFTwithLink from '../../hooks/chinaFunction/purchaseNftwithLink';
import PurchaseNFTwithPepe from '../../hooks/chinaFunction/purchaseNftwithPepe';
import PurchaseNFTwithUniswape from '../../hooks/chinaFunction/purchaseNftwithUniswape';
import AllowanceNft from "../../hooks/dataFetchers/AllowanceNft";
import ApproveNftTomi from '../../hooks/dataFetchers/ApproveNftTomi';
import PurchaseNFTwithTomi from '../../hooks/chinaFunction/purchaseNftwithTomi';
import ApproveTpl from "../../hooks/dataFetchers/ApproveTpl";
import ApproveTomi from "../../hooks/dataFetchers/ApproveTomi";
import Environment from '../../utils/Environment';
import ApproveTplUsdc from "../../hooks/dataFetchers/ApproveTplUsdc";
import ApproveTplWbtc from "../../hooks/dataFetchers/ApproveTplWbtc";
import AllowanceWbtc from '../../hooks/dataFetchers/AllowanceWbtc';
import ApproveLink from "../../hooks/dataFetchers/ApproveLink";
import ApprovePepe from "../../hooks/dataFetchers/ApprovePepe";
import useWeb3 from "../../hooks/useWeb3";
import ApproveUnisawape from "../../hooks/dataFetchers/ApproveUniswape";
import AllowancePepe from '../../hooks/dataFetchers/AllowancePepe';
import ApproveDop from '../../hooks/dataFetchers/ApproveDop';
import PurchaseNFTwithDop from '../../hooks/chinaFunction/purchaseNftwithDop';
const DopNft = ({ price, ethPricevalue, setShow, code, setLoarder, saleCurrentStaus, currentRoundd, blanceOfEth, balanceeUsdt, tomiPrice, allowanceTpl, tomiAllowance, balaceOfTmi, alowence, sallowance, discoundedPriceee, tab, wbtcCurrentPrice, getAllowanceUsdc, getAllowanceWbtc, balaceOfUsdc, balaceOfWbtc, timeshow, psMaxxCap, psmyTotlaPurchasess, setMyPsTotlaPurchasess, psTotlaPurchasess, stakeIndex, stakedAmount, discountedPriceee,round,pepePrice,linkPricevalue,uniPricevalue,uniAllowance,linkAllowance,pepeAllowance,balaceOfUnii,balaceOfPepee,balaceOfLinkk,dopAllowance,balaceOfDoppp,dopPricevalue }) => {
  const instiPrice = localStorage?.getItem("price_ins");
  const role = localStorage?.getItem("role_ins");
  const email = localStorage?.getItem('email')
  let ipBuyer = localStorage.getItem('ip')
  let countryBuyer = localStorage.getItem('country')
  // console.log(psMaxxCap, psmyTotlaPurchasess,'psMaxxCap >= psmyTotlaPurchasess');
  // const [allowanceTpl, setAllowance] = useState(null)
  const web3 = useWeb3();
  const {purchaseNFTwithDop}= PurchaseNFTwithDop()
  const {approveDop}=ApproveDop()
  const { approveLink } = ApproveLink()
  const { approvePepe } = ApprovePepe()
  const { approveUnisawape } = ApproveUnisawape()
  const { nftPrice } = NftPrice()
  const { approveTomi } = ApproveTomi()
  const { approveTpl } = ApproveTpl();
  const { approveTplUsdc } = ApproveTplUsdc()
  const { approveTplwbtc } = ApproveTplWbtc()
  const { approveNftTomi } = ApproveNftTomi()
  const { allowanceNft } = AllowanceNft();
  const { approveNft } = ApproveNft();
  const { purchaseNFTwithUsdt } = PurchaseNFTwithUsdt()
  const { purchaseNFTwithEth } = PurchaseNFTwithEth()
  const { purchaseNFTwithTomi } = PurchaseNFTwithTomi()
  const { purchaseNFTwithUsdc } = PurchaseNFTwithUsdc()
  const { purchaseNFTwithWbtc } = PurchaseNFTwithWbtc()
  const { purchaseNFTwithLink} = PurchaseNFTwithLink()
  const { purchaseNFTwithUniswape } = PurchaseNFTwithUniswape()
  const { purchaseNFTwithPepe } = PurchaseNFTwithPepe()

  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const [count5, setCount5] = useState(0);
  const [arryy, setArry] = useState([])
  const [mainTag, setMainTag] = useState("ETH")
  const [totalNFT, setTotalNft] = useState(0)
  const [totalClaim, setTotalClaim] = useState(0)
  const [totalUsdt, setUsdt] = useState(0)
  const [totalEth, setEth] = useState(0)
  const [totalTomi, setTomi] = useState(0)
  const [totalUsdc, setUsdc] = useState(0)
  const [totalWbtc, setWbtc] = useState(0)
  const [totalUni, setUni] = useState(0)
  const [totalPepe, setPepe] = useState(0)
  const [totalLink, setLink] = useState(0)
  const [totalDop, setDop] = useState(0)
  const [check, setCheck] = useState(false)
  const { account } = useWeb3React();
  const [nftPriceArryUsdt, setNftPriceArryUsdt] = useState([])
  const [nftPriceArryEth, setNftPriceArryEth] = useState([])
  const [nftPriceArryIndux, setNftPriceArryIndux] = useState([])
  const [tagImge, setTagImge] = useState('/assets/eth-icon.svg')
  const [totall, settTotal] = useState(0)
  const [totalleth, settTotaleth] = useState(0)

  let imges = [
    // { imge: '/nft-img/1.png' },
    // { imge: '/nft-img/2.png' },
    // { imge: '/nft-img/3.png' },
    // { imge: '/nft-img/4.png' },
    // { imge: '/nft-img/5.png' },
    // { imge: '/nft-img/6.png' },
    // { imge: '/nft-img/7.png' },


    { imge: '/nft-img/77.png' },
    { imge: '/nft-img/66.png' },
    { imge: '/nft-img/55.png' },
    { imge: '/nft-img/44.png' },
    { imge: '/nft-img/33.png' },
    { imge: '/nft-img/22.png' },
    { imge: '/nft-img/11.png' }
  ]
  // console.log(approveTplWbtc,'arryy');
  const handleIncrement = (i) => {
    const newData = [...arryy];
    // console.log(newData,'newData');
    newData[i].count += 1;

    newData[i].dopClaim = (newData[i].usdtPrice * newData[i].count) / price
    setArry(newData);
    disableButtonCheck()
  }

  const handleDecrement = (i) => {
    const newData = [...arryy];

    if (newData[i].count > 0) {
      newData[i].count -= 1;
      newData[i].dopClaim = (newData[i].usdtPrice * newData[i].count) / price
      setArry(newData);
    }

    disableButtonCheck()
  }

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    window.location.reload()

  };
  const handleShow2 = () => setShow2(true);


  const nftArry = async () => {
    let dummy = []
    try {
      for (let a = 0; a < 7; a++) {
        let ab = await nftPrice(a)
        let x = { usdtPrice: ab, usdcPrice: ab, wbtcPrice: ab / wbtcCurrentPrice, ethPrice: ab / ethPricevalue, tomiPrice1: ab / tomiPrice, linkPrice: ab / linkPricevalue, uniPrice: ab / uniPricevalue, pepePrice1: ab / pepePrice,dopPrice: ab / dopPricevalue, imge: imges[a].imge, count: 0, indexV: a, dopClaim: 0 }
        dummy.push(x)
      }
      setArry(dummy)
      // console.log(dummy,'dummy');
    }
    catch (err) {
      console.log(err, 'err nooor');
    }
  }
  useEffect(() => {
    if (ethPricevalue&&pepePrice&&uniPricevalue&&linkPricevalue&&dopPricevalue) {
      nftArry()
    }
  },
    [ethPricevalue,pepePrice,uniPricevalue,linkPricevalue,dopPricevalue]
  )
  const clickMe = () => {
    let a = 0;
    let bb = 0;
    let cc = 0
    let xx = 0
    let xx1 = 0
    let xx2 = 0
    let xx3 = 0
    let xx4 = 0
    let xx5 = 0
    let xx6 = 0
    let dun = []
    let dun1 = []
    let dun2 = []
    arryy.map((e) => {
      a = e.count + a
      bb = e?.dopClaim + bb

      //    console.log(e.count,e?.ethPrice,cc,'e.count');
      if (e.count > 0) {
        cc = (e?.usdtPrice * e.count) + cc
        xx = (e?.ethPrice * e.count) + xx
        xx1 = (e?.tomiPrice1 * e.count) + xx1
        xx2 = (e?.usdcPrice * e.count) + xx2
        xx3 = (e?.wbtcPrice * e.count) + xx3
        xx4= (e?.uniPrice * e.count) + xx4
        xx5= (e?.linkPrice * e.count) + xx5
        xx6= (e?.pepePrice1 * e.count) + xx6
        xx6= (e?.dopPrice * e.count) + xx6
      }
      dun.push(e.count * e?.usdtPrice)
      dun1.push(parseFloat(e.count * e?.ethPrice))
      dun2.push(e.count)


    })

    // console.log('dun',xx6);
    setTomi(xx1)
setUni(xx4)
setLink(xx5)
setPepe(xx6)
setDop(xx6)
    setUsdc(xx2)
    setWbtc(xx3)
    setTotalNft(a)
    setTotalClaim(bb)
    setMyPsTotlaPurchasess(bb + psTotlaPurchasess)
    setEth(xx)
    setUsdt(cc)
    setNftPriceArryEth(dun1)
    setNftPriceArryUsdt(dun)
    setNftPriceArryIndux(dun2)
    // console.log(mainTag, 'mainTag');
    // if (mainTag==="USDT"){
    let aa = cc
    let vv = aa / discountedPriceee
    let cccc = vv - parseFloat(bb)?.toFixed(2)
    // console.log(aa,vv,cccc,'sdssdsadas');
    settTotal(parseInt(cccc))
    let qq = cc * ethPricevalue
    let hh = qq / discountedPriceee
    let nn = hh - parseFloat(bb)?.toFixed(2)
    // console.log(qq, nn, hh, 'sdssdsadas');
    settTotaleth(parseInt(nn))
    // }

    handleShow1()


  }
  const disableButtonCheck = () => {
    let b = arryy.find((e) => e.count > 0)
    if (b) {
      setCheck(true)
    } else {
      setCheck(false)
    }

  }
  const submitt = async () => {
    setLoarder(true)
    let config
    if (mainTag == 'TOMI') {
      config = {
        method: "post",
        url: `${API_URL}users/buy-nft-with-tomi`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": email,
        },
      };
    }
    else if (mainTag == 'ETH') {
      config = {
        method: "post",
        url: `${API_URL}users/buy-nft`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": countryBuyer,
          "tokensAmount": totalEth,
          "unitPrice": ethPricevalue,
          email: email

        },
      };
    }
    else if (mainTag == 'USDC') {
      config = {
        method: "post",
        url: `${API_URL}users/buy-nft-stable-coin-usdc`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": countryBuyer,
          "tokensAmount": totalUsdc,
          "unitPrice": 1,
          email: email
        },
      };
    }
    else if (mainTag == 'WBTC') {
      config = {
        method: "post",
        url: `${API_URL}users/buy-nft-wbtc`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": countryBuyer,
          "tokensAmount": totalWbtc,
          "unitPrice": wbtcCurrentPrice,
          email: email

        },
      };
    }
    else if (mainTag == 'UNI') {
      // let pricee= uniPricevalue
      // pricee =parseFloat(pricee).toFixed(6)
      // let amount1 = Math.round((pricee) * 1e12) / 1e12;
      // let nd = web3.utils.toWei(amount1.toString(), "mwei");
      config = {
        method: "post",
        url: `${API_URL}users/buy-with-tokens`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": countryBuyer,
          // "price": nd,
          email: email,
          "tokenSelected":"UNI",
          "type":"NFT"
        },
      };
    }
    else if (mainTag == 'LINK') {
      // let pricee= uniPricevalue
      // pricee =parseFloat(pricee).toFixed(6)
      // let amount1 = Math.round((pricee) * 1e12) / 1e12;
      // let nd = web3.utils.toWei(amount1.toString(), "mwei");
      config = {
        method: "post",
        url: `${API_URL}users/buy-with-tokens`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": countryBuyer,
          // "price": nd,
          email: email,
          "tokenSelected":"LINK",
          "type":"NFT"
        },
      };
    }
    else if (mainTag == 'DOP') {
      // let pricee= uniPricevalue
      // pricee =parseFloat(pricee).toFixed(6)
      // let amount1 = Math.round((pricee) * 1e12) / 1e12;
      // let nd = web3.utils.toWei(amount1.toString(), "mwei");
      config = {
        method: "post",
        url: `${API_URL}users/buy-with-tokens`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": countryBuyer,
          // "price": nd,
          email: email,
          "tokenSelected":"DOP",
          "type":"NFT"
        },
      };
    }
    else if (mainTag == 'PEPE') {
      // let pricee= uniPricevalue
      // pricee =parseFloat(pricee).toFixed(6)
      // let amount1 = Math.round((pricee) * 1e12) / 1e12;
      // let nd = web3.utils.toWei(amount1.toString(), "mwei");
      config = {
        method: "post",
        url: `${API_URL}users/buy-with-tokens`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": countryBuyer,
          // "price": nd,
          email: email,
          "tokenSelected":"PEPE",
          "type":"NFT"
        },
      };
    }
    else {
      config = {
        method: "post",
        url: `${API_URL}users/buy-nft-stable-coin`,
        data: {
          accessCode: code,
          walletAddress: account.toString(),
          "country": countryBuyer,
          "tokensAmount": totalUsdt,
          "unitPrice": 1,
          email: email
          // buyingAmount: amountInUsdt,
        },
      }
    }
    const postApiRes = await axios(config)
      .then(function (response) {
        return response?.data?.data;
      })
      .catch(function (error) {
        toast.error(error?.response?.data.message);
      });
    // console.log();
    if (mainTag === "USDT") {
      if (sallowance === 0) {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              if (balanceeUsdt >= totalUsdt) {
                let z = await approveTpl(10000000, account);
                if (z) {
                  let q = await purchaseNFTwithUsdt(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                }
              } else {
                toast.error("balance is low");
                setLoarder(false);
              }

            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }


          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (totalUsdt > sallowance) {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balanceeUsdt >= totalUsdt) {

                  let res = await approveNft(0, account);
                  if (res) {
                    let res11 = await approveTpl(10000000, account);

                    if (res11) {
                      let q = await purchaseNFTwithUsdt(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                      if (q) {
                        confirmBuy(q)
                        alowence();
                        handleShow2()
                        setLoarder(false);

                      }
                    }
                  }

                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            } else {
              setLoarder(false);
            }
          } else {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balanceeUsdt >= totalUsdt) {
                  let q = await purchaseNFTwithUsdt(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }
            else {
              setLoarder(false);
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      }

    }
    else if (mainTag === "USDC") {
      if (getAllowanceUsdc === 0) {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              if (balaceOfUsdc >= totalUsdt) {
                let z = await approveTplUsdc(10000000, account);
                if (z) {
                  let q = await purchaseNFTwithUsdc(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                }
              } else {
                toast.error("balance is low");
                setLoarder(false);
              }

            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }


          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (totalUsdc > getAllowanceUsdc) {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfUsdc >= totalUsdc) {

                  let res11 = await approveTplUsdc(10000000, account);

                  if (res11) {
                    let q = await purchaseNFTwithUsdc(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                    if (q) {
                      confirmBuy(q)
                      alowence();
                      handleShow2()
                      setLoarder(false);

                    }

                  }

                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            } else {
              setLoarder(false);
            }
          } else {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfUsdc >= totalUsdc) {
                  let q = await purchaseNFTwithUsdc(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }
            else {
              setLoarder(false);
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      }

    }
    else if (mainTag === "WBTC") {
      if (AllowanceWbtc === 0) {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              if (balaceOfWbtc >= totalWbtc) {
                let z = await approveTplwbtc(10000000, account);
                if (z) {
                  let q = await purchaseNFTwithWbtc(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                }
              } else {
                toast.error("balance is low");
                setLoarder(false);
              }

            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }


          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (totalWbtc > AllowanceWbtc) {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfWbtc >= totalWbtc) {
                  let res11 = await approveTplwbtc(10000000, account);

                  if (res11) {
                    let q = await purchaseNFTwithWbtc(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth);
                    if (q) {
                      confirmBuy(q)
                      alowence();
                      handleShow2()
                      setLoarder(false);

                    }

                  }

                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            } else {
              setLoarder(false);
            }
          } else {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfWbtc >= totalWbtc) {
                  let q = await purchaseNFTwithWbtc(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }
            else {
              setLoarder(false);
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      }

    }
    else if (mainTag === "UNI") {
      if (uniAllowance === 0) {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              if (balaceOfUnii>= totalUni) {
                let z = await approveUnisawape(10000000, account);
                if (z) {
                  let q = await purchaseNFTwithUniswape(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                }
              } else {
                toast.error("balance is low");
                setLoarder(false);
              }

            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }


          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (totalUni > uniAllowance) {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfUnii >= totalUni) {
                  let res11 = await approveUnisawape(10000000, account);

                  if (res11) {
                    let q = await purchaseNFTwithUniswape(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                    if (q) {
                      confirmBuy(q)
                      alowence();
                      handleShow2()
                      setLoarder(false);

                    }

                  }

                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            } else {
              setLoarder(false);
            }
          } else {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfUnii >= totalUni) {
                  let q = await purchaseNFTwithUniswape(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }
            else {
              setLoarder(false);
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      }

    }
    else if (mainTag === "LINK") {
      if (linkAllowance === 0) {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              if (balaceOfLinkk >= totalLink) {
                let z = await approveLink(10000000, account);
                if (z) {
                  let q = await purchaseNFTwithLink(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                }
              } else {
                toast.error("balance is low");
                setLoarder(false);
              }

            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }


          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (totalLink > linkAllowance) {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfLinkk >= totalLink) {
                  let res11 = await approveLink(10000000, account);

                  if (res11) {
                    let q = await purchaseNFTwithLink(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                    if (q) {
                      confirmBuy(q)
                      alowence();
                      handleShow2()
                      setLoarder(false);

                    }

                  }

                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            } else {
              setLoarder(false);
            }
          } else {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfLinkk>= totalLink) {
                  let q = await purchaseNFTwithLink(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }
            else {
              setLoarder(false);
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      }

    }
    else if (mainTag === "DOP") {
      if (dopAllowance === 0) {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              if (balaceOfDoppp >= totalDop) {
                let z = await approveDop(10000000, account);
                if (z) {
                  let q = await purchaseNFTwithDop(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                }
              } else {
                toast.error("balance is low");
                setLoarder(false);
              }

            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }


          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (totalDop > dopAllowance) {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfDoppp >= totalDop) {
                  let res11 = await approveDop(10000000, account);

                  if (res11) {
                    let q = await purchaseNFTwithDop(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                    if (q) {
                      confirmBuy(q)
                      alowence();
                      handleShow2()
                      setLoarder(false);

                    }

                  }

                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            } else {
              setLoarder(false);
            }
          } else {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfDoppp>= totalDop) {
                  let q = await purchaseNFTwithDop(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }
            else {
              setLoarder(false);
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      }

    }
    else if (mainTag === "PEPE") {
      if (pepeAllowance === 0) {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              if (balaceOfPepee >= totalPepe) {
                let z = await approvePepe(10000000, account);
                if (z) {
                  let q = await purchaseNFTwithPepe(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                }
              } else {
                toast.error("balance is low");
                setLoarder(false);
              }

            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }


          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (totalPepe > pepeAllowance) {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfPepee >= totalPepe) {
                  let res11 = await approvePepe(10000000, account);

                  if (res11) {
                    let q = await purchaseNFTwithPepe(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                    if (q) {
                      confirmBuy(q)
                      alowence();
                      handleShow2()
                      setLoarder(false);

                    }

                  }

                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            } else {
              setLoarder(false);
            }
          } else {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfPepee >= totalPepe) {
                  let q = await purchaseNFTwithPepe(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }
            else {
              setLoarder(false);
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      }

    }

    else if (mainTag === "ETH") {
      if (postApiRes) {
        if (saleCurrentStaus) {
          // console.log(blanceOfEth,totalEth,'khna',blanceOfEth<totalEth);
          if (blanceOfEth > totalEth) {
            // console.log('in function call');
            try {
              let b = blanceOfEth - parseFloat(totalEth)
              let qw = await purchaseNFTwithEth(postApiRes, currentRoundd, nftPriceArryIndux, price, totalEth, b, stakeIndex)
              if (qw) {
                confirmBuy(qw)
                handleShow2()
                setLoarder(false);

              }
            }
            catch (err) {
              console.log(err);
              toast.error("transection reverted");
              setLoarder(false);
            }
          } else {
            toast.error("balance is low");
            setLoarder(false);
          }
        } else {
          toast.error("Sale is Currently Paused");
          setLoarder(false);
        }

      } else {
        console.log('api erro');
        setLoarder(false);
      }

    }
    else {
      if (tomiAllowance === 0) {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              if (balaceOfTmi >= totalTomi) {
                let z = await approveTomi(10000000, account);
                if (z) {
                  let q = await purchaseNFTwithTomi(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, tomiPrice, blanceOfEth, stakeIndex);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                }
              } else {
                toast.error("balance is low");
                setLoarder(false);
              }

            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }


          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (totalTomi > tomiAllowance) {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfTmi >= totalTomi) {


                  let res11 = await approveTomi(10000000, account);

                  if (res11) {
                    let q = await purchaseNFTwithTomi(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, tomiPrice, blanceOfEth);
                    if (q) {
                      confirmBuy(q)
                      alowence();
                      handleShow2()
                      setLoarder(false);

                    }
                  }


                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            } else {
              setLoarder(false);
            }
          } else {
            if (postApiRes) {
              if (saleCurrentStaus) {
                if (balaceOfTmi >= totalTomi) {
                  let q = await purchaseNFTwithTomi(postApiRes, currentRoundd, nftPriceArryIndux, price, totalUsdt, tomiPrice, blanceOfEth);
                  if (q) {
                    confirmBuy(q)
                    alowence();
                    handleShow2()
                    setLoarder(false);
                  }
                } else {
                  toast.error("balance is low");
                  setLoarder(false);
                }
              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }
            else {
              setLoarder(false);
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          alowence();
          toast.error("Transaction reverted");
        }
      }
    }
    // handleShow2()
  }
  // const alowence = async () => {
  //     try {
  //       let r = await allowanceNft();
  //     //   console.log( r, 'nooorrr alowance');
  //       setAllowance(r);
  //     //   setAllowance1(r1);
  //     } catch (err) {
  //     }
  //   };
  // useEffect(()=>{
  //     if (account)
  //     {
  //         alowence()
  //     }
  // },[account])


  const confirmBuy = async (dataaa) => {
    // console.log(data, api, inputFeild5, price, inputFeild1, inputFeild, mainTag, 'noooooor');
    const config = {
      method: "post",
      url: `${API_URL}emails/nft`,
      data: {
        "accessCode": code,
        "transactionHash": dataaa?.transactionHash,
        "accessCodeRole": role,
        "by": account,
        "price": stakedAmount >= 18000?discoundedPriceee: price,
        "recipientEmail": email,
        "tokenSelected": mainTag,
      },

    };
    const postApiRes = await axios(config)
      .then(function (response) {
        // return response?.data?.data;
      })
      .catch(function (error) {
        // toast.error(error?.response?.data.message);
      });

  }


  const changeTag1 = (e) => {
    if (e === "ETH") {
      setMainTag("ETH");
      setTagImge('/assets/eth-icon.svg')
      discoundedPriceee(currentRoundd, Environment.eth)
      // setMainTag1("ETH");
      //   setInputField1('');
      //   setInputField('');
      // }
    } else if (e == "USDT") {
      setMainTag("USDT");
      setTagImge('/assets/usdt.svg')
      discoundedPriceee(currentRoundd, Environment.busd)
      // setMainTag1("USDT");
      //   setInputField1('');
      //   setInputField('');
    }
    else if (e == "USDC") {
      discoundedPriceee(currentRoundd, Environment.usdc)
      setMainTag("USDC");
      setTagImge('/assets/usdc.svg')
    }
    else if (e == "WBTC") {
      discoundedPriceee(currentRoundd, Environment.wbtc)
      setMainTag("WBTC");
      setTagImge('/assets/wbtc.svg')
    }
    else if (e == "LINK") {
      discoundedPriceee(currentRoundd, Environment.link)
      setMainTag("LINK");
      setTagImge('/assets/link.svg')
    }
    else if (e == "DOP") {
      discoundedPriceee(currentRoundd, Environment.dop)
      setMainTag("DOP");
      setTagImge('/assets/dop.svg')
    }
    else if (e == "UNI") {
      discoundedPriceee(currentRoundd, Environment.unisape)
      setMainTag("UNI");
      setTagImge('/assets/uniswap.svg')
    }
    else if (e == "PEPE") {
      discoundedPriceee(currentRoundd, Environment.pepe)
      setMainTag("PEPE");
      setTagImge('/assets/pepe.svg')
    }
    else {
      setMainTag("TOMI");
      setTagImge('/assets/tomi-icon-drop.svg')
      discoundedPriceee(currentRoundd, Environment.tomi)
      // setMainTag1("USDT");
      //   setInputField1('');
      //   setInputField('');

    }
  };
  useEffect(() => {
    discoundedPriceee(currentRoundd, Environment.eth)
    setMainTag("ETH");
    setTagImge('/assets/eth-icon.svg')
  }, [tab])
  return (
    <>
      <section className="dop_nft">
        <div class="dropdown select-drop">
          <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
            <div className="twice">
              <div className='inner-flex'><img style={{ width: "28px", height: "28px", objectFit: "cover" }} src={tagImge} alt="img" className='img-fluid' /> {mainTag}</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                <path d="M7.81779 1.79707L5.9829 3.74596L4.86253 4.94201C4.38809 5.44593 3.61641 5.44593 3.14197 4.94201L0.181004 1.79707C-0.207694 1.38422 0.0723969 0.679946 0.615431 0.679946H3.82219H7.38336C7.93211 0.679946 8.20648 1.38422 7.81779 1.79707Z" fill="black" />
              </svg>
            </div>
          </button>
          {/* <ul class="dropdown-menu">
                        <li><a class="dropdown-item" onClick={() => {
                            setMainTag("USDT")
                        }} ><img src="\assets\usdt-icon.svg" alt="img" className='img-fluid' /> USDT</a></li>
                        <li><a class="dropdown-item" onClick={() => {
                            setMainTag("ETH")
                        }} ><img src="\assets\eth-icon-new.svg" alt="img" className='img-fluid' /> ETH</a></li>
                    </ul> */}
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('ETH')} >
                <img
                  src="/assets/eth-icon.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
                ETH
              </a>
            </li>
            <li>
              <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDT')} >
                <img
                  src="/assets/usdt.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
                USDT
              </a>
            </li>
            {/* <li>
            <a class="dropdown-item gscrxcsx"  onClick={()=>changeTag1('TOMI')}>
              <img
                src="\assets\tomi-icon-drop.svg"
                alt="img"
                className="img-fluid"
              />{" "}
             TOMI
            </a>
          </li> */}
            <li>
              <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDC')}>
                <img
                  src="\assets\usdc.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
                USDC
              </a>
            </li>
            <li>
              <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('UNI')}>
                <img
                  src="\assets\uniswap.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
                UNI
              </a>
            </li>
            <li>
              <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('LINK')}>
                <img
                  src="\assets\link.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
                LINK
              </a>
            </li>
            <li>
              <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('DOP')}>
                <img
                  src="\assets\dop.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
              DOP
              </a>
            </li>
            <li>
              <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('PEPE')}>
                <img
                  src="\assets\pepe.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
                PEPE
              </a>
            </li>
            {/* <li>
              <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('WBTC')}>
                <img
                  src="\assets\wbtc.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
                WBTC
              </a>
            </li> */}
          </ul>

        </div>
        <div className="parent-card">
          {arryy.map((e, i) => {
            return (

              <div className="inner-card">
                <div className="upper-img">
                  <img src={e?.imge} alt="img" className='img-fluid' />
                </div>
                <div className="bottom-text">
                  <div className="inner-text">
                    <p>Price {mainTag === 'USDT' ? 'USDT' : mainTag === 'ETH' ? "ETH" : mainTag === 'TOMI' ? "TOMI" : mainTag === 'USDC' ? 'USDC' : mainTag === 'UNI' ? 'UNI': mainTag === 'LINK' ? 'LINK': mainTag === 'PEPE' ? 'PEPE': mainTag === 'DOP' ? 'DOP':"WBTC"}</p>
                    <h6>{mainTag === 'USDT' ? '$' : ""}{mainTag === 'USDT' ? e?.usdtPrice : mainTag === 'ETH' ? parseFloat(e?.ethPrice).toFixed(4) : mainTag === 'TOMI' ? parseFloat(e?.tomiPrice1).toFixed(4) : mainTag === 'USDC' ? parseFloat(e?.usdcPrice).toFixed(4) :mainTag === 'UNI' ? parseFloat(e?.uniPrice).toFixed(4) :mainTag === 'LINK' ? parseFloat(e?.linkPrice).toFixed(4) :mainTag === 'PEPE' ? parseFloat(e?.pepePrice1).toFixed(4) :mainTag === 'DOP' ? parseFloat(e?.dopPrice).toFixed(4):parseFloat(e?.wbtcPrice).toFixed(4)}</h6>
                  </div>
                  <div className="inner-text">
                    <p>Claimable $INC</p>
                    <h6>{parseFloat(e?.dopClaim).toFixed(4)}</h6>
                  </div>
                </div>
                <div className="select-nft">
                  <div className="left">
                    {
                      e?.count == 0 ? <img src="\assets\minusnft.svg" alt="img" className='img-fluid' style={{ opacity: "0.3", pointerEvents: "none" }} /> : <img onClick={(event) => handleDecrement(i)} src="\assets\minusnft.svg" alt="img" className='img-fluid' />
                    }

                  </div>
                  <div className="mid">
                    <h6>{e?.count}</h6>
                  </div>
                  <div className="right">
                    <img onClick={(event) => handleIncrement(i)} src="\assets\plusnft.svg" alt="img" className='img-fluid' />
                  </div>
                </div>
              </div>
            )
          })
          }

        </div>

        <div className="bottom-btn">

          {account ?
            <>
              {!timeshow ?

                // <>
                //   {psMaxxCap >= psmyTotlaPurchasess ?

                <>
                  {check ?
                    <button onClick={clickMe} className="btn-buy-nft">Buy NFTs</button>
                    :
                    <button className="btn-buy">Buy NFTs</button>
                  }

                </>
                :
                <button className="btn-buy">Buy NFTs</button>
              }
            </>

            :
            <button className="btn-buy">Buy NFTs</button>
          }

          {/* </>
            :
            <button onClick={() => {
              setShow(true)
            }} className="btn-buy">Buy NFTs</button>
          } */}


        </div>
      </section>


      <Modal
        className="connectwallet-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy NFTs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buydopnft-modal">
            <div className="inner-text">
              <p>No. of NFTs</p>
              <h6>{totalNFT}</h6>
            </div>
            <div className="inner-text">
              <p>INC Price</p>
              <h6>${      stakedAmount >= 5555 ?discountedPriceee?.toFixed(4):price}</h6>
            </div>
            <div className="inner-text">
              <p>Total in {mainTag === 'USDT' ? 'USDT' : mainTag === 'ETH' ? "ETH" : mainTag === 'TOMI' ? "TOMI" : mainTag === 'USDC' ? 'USDC' :  mainTag === 'UNI' ? 'UNI': mainTag === 'LINK' ? 'LINK': mainTag === 'PEPE' ? 'PEPE': mainTag === 'DOP' ? 'DOP':'WBTC'}</p>
              <h6>{mainTag === 'USDT' ? totalUsdt : mainTag === 'ETH' ? parseFloat(totalEth).toFixed(4) : mainTag === 'TOMI' ? parseFloat(totalTomi).toFixed(4) : mainTag === 'USDC' ? parseFloat(totalUsdc).toFixed(4) : mainTag === 'UNI' ? parseFloat(totalUni).toFixed(4):mainTag === 'LINK' ? parseFloat(totalLink).toFixed(4):mainTag === 'PEPE' ? parseFloat(totalPepe).toFixed(4):mainTag === 'DOP' ? parseFloat(totalDop).toFixed(4):parseFloat(totalWbtc).toFixed(4)}</h6>
            </div>
            {/* <div className="inner-text">
              <p>sub total Claimable $INC</p>
              <h6>{parseFloat(totalClaim)?.toFixed(4)}{" "}INC</h6>
            </div> */}
            {/* <div className="inner-text">
              <p>{round == 1 ? '20' : "7"}% Additional Tokens:Claimable $INC</p>
              <h6>{stakedAmount >= 5555 ?
                parseFloat(totall)?.toFixed(4)
                : 0.00
              }{" "}INC</h6>
            </div> */}
            <div className="inner-text">
              <p>total Claimable $INC</p>
              <h6>
                {stakedAmount >= 5555 ?
                  
                     (totall+totalClaim).toFixed(2)
                  :
                  parseFloat(totalClaim)?.toFixed(4)
                }
                {" "}INC</h6>
            </div>
          </div>
          <div className="twice-btnsss">
            <button onClick={handleClose1} className='btn-backkk'>Back</button>

            <button onClick={submitt} className='btn-proceed '>proceed</button>




          </div>
        </Modal.Body>
      </Modal>


      <Modal
        className="connectwallet-modal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy NFTs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buydopnft-modal buynft-modal2">
            <img src="\assets\happyface.svg" alt="img" className='img-fluid' />
            <h5>You have successfully purchased <span> {totalNFT} INC {totalNFT > 0 ? 'NFT' : "NFTs"}</span> worth <span>  {stakedAmount >= 5555 ?
                  
                  (totall+totalClaim).toFixed(2)
               :
               parseFloat(totalClaim)?.toFixed(4)
             } INC</span></h5>
          </div>
          <div className="twice-btnsss">
            <button onClick={handleClose2} className='btn-proceed sscscscscscsc'>Okay</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DopNft
