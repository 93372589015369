// export const API_URL = 'http://192.168.10.158:4000/'
// export const API_URL = 'http://192.168.0.245:8080/v1/' 
// export const API_URL = 'http://ec2-54-190-35-48.us-west-2.compute.amazonaws.com:8082/v1/'
// export const API_URL = "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8000/"


// main wala 
export const API_URL = "https://prod-api.gems.vip/"


// export const API_URL = "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8007/"
// 


// export const API_URL = "http://192.168.20.198:8000/"
// export const BASE_URL = 'https://tomipay-staging.tomi.com/';
// export const CoingekoBaseURL = 'https://pro-api.coingecko.com/api/v3';
// export const eth = "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
// export const API_URL = 'http://192.168.0.154:8081/v1/'