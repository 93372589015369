let Environment = {
  nftPurchase: "0x1119c2d708E42Fb591FCe77283b0791a135855ba",
  chinaTomi: "0x20361bC401EA01e5d6E01596c445Af1a48e1171b",
  busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  instituson: "0x09e4f3E65ce7c16566DdFA0D860D4d95a26698ca",
  eth:"0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc:'0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  wbtc:'0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  gems: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc", 
  GemsStaking: "0x6A1aa5ac35f38Bdb25BBD976E8eca942b23260b2", 
  link: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
  pepe: "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
  unisape: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
  dop:'0x97A9a15168C22B3C137E6381037E1499C8ad0978'
};
export default Environment;