import React, { useState, useEffect } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../header/Navbar";
import Modal from "react-bootstrap/Modal";
import EthPrice from "../../../hooks/chinaFunction/ethPrice";
import UniPrice from "../../../hooks/chinaFunction/uniPrice";
import LinkPrice from "../../../hooks/chinaFunction/linkPrice";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../../hooks/useWeb3";
import moment from "moment";
import AllowanceTpl from "../../../hooks/dataFetchers/AllowanceTpl";
import AllowanceTpl1 from "../../../hooks/dataFetchers/AllowanceTpl1";
import ApproveTpl from "../../../hooks/dataFetchers/ApproveTpl";
import ApproveTplUsdc from "../../../hooks/dataFetchers/ApproveTplUsdc";
import ApproveTplUsdc1 from "../../../hooks/dataFetchers/approveTplUsdc1";
import ApproveTplWbtc from "../../../hooks/dataFetchers/ApproveTplWbtc";
import ApproveTplWbtc1 from "../../../hooks/dataFetchers/approveTplWbtc1";
import ApproveTomi from "../../../hooks/dataFetchers/ApproveTomi";
import ApproveLink from "../../../hooks/dataFetchers/ApproveLink";
import ApprovePepe from "../../../hooks/dataFetchers/ApprovePepe";
import ApproveUnisawape from "../../../hooks/dataFetchers/ApproveUniswape";
import ApproveTomi1 from "../../../hooks/dataFetchers/ApproveTomi1";
import ApproveTpl1 from "../../../hooks/dataFetchers/ApproveTpl1";
import BalacefAccount from "../../../hooks/dataFetchers/BalaceOfAccount";
import PurchaseEth from "../../../hooks/chinaFunction/purchaseEth";
import PurchaseEth1 from "../../../hooks/chinaFunction/purchaseEth1";
import PurchaseUsdt from "../../../hooks/chinaFunction/purchaseUsdt";
import PurchaseUsdc from "../../../hooks/chinaFunction/purchaseUsdc";
import PurchaseUsdc1 from "../../../hooks/chinaFunction/purchaseUsdc1";
import PurchaseWbtc from "../../../hooks/chinaFunction/purchaseWbtc";
import PurchaseWbtc1 from "../../../hooks/chinaFunction/purchaseWbtc1";
import PurchaseUsdt1 from "../../../hooks/chinaFunction/purchaseUsdt1";
import PurchasePepe from "../../../hooks/chinaFunction/purchasePepe";
import PurchaseLink from "../../../hooks/chinaFunction/purchaseLink";
import PurchaseUniswape from "../../../hooks/chinaFunction/purchaseUniswape";
import Loader from "../../../hooks/loader";
import axios from "axios";
import { API_URL } from "../../../utils/ApiUrl";
import { toast } from "react-toastify";
import CuurentRound from "../../../hooks/chinaFunction/round";
import CuurentRoundNft from "../../../hooks/chinaFunction/roundNft";
import SaleStatus from "../../../hooks/chinaFunction/saleStatus";
import SaleStatusNft from "../../../hooks/chinaFunction/saleStatusNft";
import PsMarketCap from "../../../hooks/chinaFunction/psMarketCap";
import InMarketCap from "../../../hooks/chinaFunction/inMarketCap";
import SaleStatus1 from "../../../hooks/chinaFunction/saleStatus1";
import InTotalPurchase from "../../../hooks/chinaFunction/inTotalPurchase";
import PsTotalPurchase from "../../../hooks/chinaFunction/psTotalPurchase";
import Timer from "../../../hooks/chinaFunction/timer";
import TimerNft from "../../../hooks/chinaFunction/timeNft";
import { useHistory } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DopNft from "../../DopNft/DopNft";
import BalacefAccountTomi from "../../../hooks/dataFetchers/BlanaceOfAccountTom";
import BalacefAccountLink from "../../../hooks/dataFetchers/BalacefAccountLink";
import BalacefAccountPepe from "../../../hooks/dataFetchers/BalacefAccountPepe";
import BalacefAccountUni from "../../../hooks/dataFetchers/BalacefAccountUni";
import BalacefAccountUsdc from "../../../hooks/dataFetchers/BalanceOfAccountUsdc";
import BalacefAccountWbtc from "../../../hooks/dataFetchers/BalanceOfAccountWbtc";
import AllowanceTomi from "../../../hooks/dataFetchers/AllowanceTomi";
import AllowanceLink from "../../../hooks/dataFetchers/AllowanceLink";
import AllowancePepe from "../../../hooks/dataFetchers/AllowancePepe";
import AllowanceUniswape from "../../../hooks/dataFetchers/AllowanceUniswape";
import AllowanceUsdc from "../../../hooks/dataFetchers/AllowanceUsdc";
import AllowanceUsdc1 from "../../../hooks/dataFetchers/AllowanceUsdc1";
import AllowanceWbtc from "../../../hooks/dataFetchers/AllowanceWbtc";
import AllowanceWbtc1 from "../../../hooks/dataFetchers/AllowanceWbtc1";
import AllowanceTomi1 from "../../../hooks/dataFetchers/AllowanceTomi1";
import PurchaseTomi from "../../../hooks/chinaFunction/purchaseTomi";
import PurchaseTomi1 from "../../../hooks/chinaFunction/puchaseTomi1";
import DiscountedPrice from "../../../hooks/chinaFunction/discountedPrice";
import Environment from "../../../utils/Environment";
import WbtcPrice from "../../../hooks/chinaFunction/wbtcPrice";
import AllowanceDop from "../../../hooks/dataFetchers/AllowanceDop";
import ApproveDop from "../../../hooks/dataFetchers/ApproveDop";
import PurchaseDop from "../../../hooks/chinaFunction/purchaseDop";
import BalacefAccountDop from "../../../hooks/dataFetchers/balanceOfAccountDop";
import { setupNetwork } from "../../../utils/wallet";
import { map, set } from "lodash";
import { ProgressBar } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import GetGemsStakedIndex from "../../../hooks/chinaFunction/getGemsStakedIndex";
import GetGemsStaked from "../../../hooks/chinaFunction/getGemsStakeAmount";
const Banner = ({ setShow }) => {
  let ipBuyer = localStorage.getItem('ip')
  let countryBuyer = localStorage.getItem('country')
  // console.log(ipBuyer,countryBuyer,'countryBuyer');
  const { allowanceDop } = AllowanceDop();
  const { approveDop } = ApproveDop();
  const { purchaseDop } = PurchaseDop();
  const { balacefAccountDop } = BalacefAccountDop();
  const history = useHistory();
  const { getGemsStaked } = GetGemsStaked();
  const { getGemsStakedIndex } = GetGemsStakedIndex()
  const { allowanceLink } = AllowanceLink()
  const { allowanceTomi } = AllowanceTomi()
  const { allowancePepe } = AllowancePepe()
  const { allowanceUniswape } = AllowanceUniswape()
  const { allowanceUsdc } = AllowanceUsdc()
  const { allowanceWbtc } = AllowanceWbtc()
  const { allowanceUsdc1 } = AllowanceUsdc1()
  const { allowanceWbtc1 } = AllowanceWbtc1()
  const { allowanceTomi1 } = AllowanceTomi1()
  const { cuurentRound } = CuurentRound()
  const { cuurentRoundNft } = CuurentRoundNft()
  const { saleStatus } = SaleStatus()
  const { saleStatusNft } = SaleStatusNft()

  const { psMarketCap } = PsMarketCap()
  const { inMarketCap } = InMarketCap()
  const { psTotalPurchase } = PsTotalPurchase()
  const { inTotalPurchase } = InTotalPurchase()
  const { saleStatus1 } = SaleStatus1()
  const { timer } = Timer()
  const { timerNft } = TimerNft()
  const { purchaseTomi } = PurchaseTomi()
  const { purchaseTomi1 } = PurchaseTomi1()
  const { discountedPrice } = DiscountedPrice()
  const [psTotlaPurchasess, setPsTotlaPurchasess] = useState(null)
  const [inTotlaPurchasess, setInTotlaPurchasess] = useState(null)
  const [psmyTotlaPurchasess, setMyPsTotlaPurchasess] = useState(null)
  const [inMyTotlaPurchasess, setMyInTotlaPurchasess] = useState(null)
  const [psMaxxCap, setPsMaxxCap] = useState(null)
  const [inMaxxCap, setInMaxxCap] = useState(null)
  const [stakeEndTime, setStakeEndTime] = useState([]);
  const [stakingData, setStakingData] = useState([]);
  var now11 = new Date();
  let createdDate = now11?.getTime()
  // console.log(createdDate,'createdDate');
  // const capHandler = async () => {
  //   try {


  //     // const { psMarketCap } = PsMarketCap()
  //     // const { inMarketCap } = InMarketCap()
  //     // const { psTotalPurchase } = PsTotalPurchase()
  //     // const { inTotalPurchase } = InTotalPurchase()
  //     let a = await psMarketCap()
  //     setPsMaxxCap(a)
  //     let b = await inMarketCap()
  //     setInMaxxCap(b)
  //     let c = await psTotalPurchase()
  //     setPsTotlaPurchasess(c)
  //     let v = await inTotalPurchase()
  //     setInTotlaPurchasess(v)
  //     // console.log(a,b,v,c,'total cap');
  //   } catch (err) {
  //     console.log(err, 'err from cap');

  //   }
  // }
  // // console.log(psMaxxCap,psmyTotlaPurchasess,'nooooooooor',psTotlaPurchasess);
  let currentTime = createdDate / 1000;
  currentTime = parseInt(currentTime)
  const objjjj = localStorage?.getItem("oobjj");
  const code = localStorage?.getItem("accessCode");
  const instiPrice = localStorage?.getItem("price_ins");
  const role = localStorage?.getItem("role_ins");
  const email = localStorage?.getItem('email')
  const modalTwitter = localStorage?.getItem('twittter')
  const [id, setId] = useState(null)
  const [price, setPrice] = useState(null)
  const [round, setRound] = useState(null)
  const [loaderr, setLoarder] = useState(false);
  const [loaderr1, setLoarder1] = useState(false);
  const { purchaseUsdt } = PurchaseUsdt();
  const { purchaseUsdc } = PurchaseUsdc()
  const { purchaseUsdc1 } = PurchaseUsdc1()
  const { purchaseWbtc } = PurchaseWbtc()
  const { purchaseWbtc1 } = PurchaseWbtc1()
  const { purchasePepe } = PurchasePepe()
  const { purchaseLink } = PurchaseLink()
  const { purchaseUniswape } = PurchaseUniswape()
  const { purchaseEth } = PurchaseEth();
  const { purchaseUsdt1 } = PurchaseUsdt1();
  const { purchaseEth1 } = PurchaseEth1();
  const { approveTpl } = ApproveTpl();
  const { approveTplUsdc } = ApproveTplUsdc()
  const { approveTplwbtc } = ApproveTplWbtc()
  const { approveTplUsdc1 } = ApproveTplUsdc1()
  const { approveTplwbtc1 } = ApproveTplWbtc1()
  const { approveTomi } = ApproveTomi()
  const { approveLink } = ApproveLink()
  const { approvePepe } = ApprovePepe()
  const { approveUnisawape } = ApproveUnisawape()
  const { approveTomi1 } = ApproveTomi1()
  const { approveTpl1 } = ApproveTpl1();
  const { allowanceTpl } = AllowanceTpl();
  const { allowanceTpl1 } = AllowanceTpl1();
  const { balacefAccount } = BalacefAccount();
  const { balacefAccountTomi } = BalacefAccountTomi()
  const { balacefAccountLink } = BalacefAccountLink()
  const { balacefAccountUni } = BalacefAccountUni()
  const { balacefAccountPepe } = BalacefAccountPepe()
  const { balacefAccountUsdc } = BalacefAccountUsdc()
  const { balacefAccountWbtc } = BalacefAccountWbtc()
  const [currentRoundd, setCurrentRoundd] = useState(null)
  const [copyOfPrice, setCopyOfPrice] = useState(null)
  const [priceCheck, setPriceCheck] = useState(true)
  const web3 = useWeb3();
  let now = Date.now();
  const { ethPrice } = EthPrice();
  const { linkPrice } = LinkPrice();
  const { uniPrice } = UniPrice();
  const { wbtcPrice } = WbtcPrice()
  const [inputFeild5, setInputField5] = useState(null);
  const [inputFeild, setInputField] = useState(null);
  const [inputFeild1, setInputField1] = useState(null);
  const [ethPricevalue, setEthPrice] = useState(0);
  const [uniPricevalue, setUniPrice] = useState(0);
  const [dopPricevalue, setDopPrice] = useState(0);
  const [linkPricevalue, setlinkPrice] = useState(0);
  const [blanceOfEth, setBlanceOfEth] = useState(0);
  const [date, setDate] = useState(0);
  const [mainTag, setMainTag] = useState("ETH");
  const [mainTag1, setMainTag1] = useState("USDT");
  const { account, chainId } = useWeb3React();
  const [balanceeUsdt, setBalanceeUsdt] = useState(null);
  const [sallowance, setAllowance] = useState(null);
  const [getAllowanceUsdc, setAllowanceUsdc] = useState(null);
  const [getAllowanceWbtc, setAllowanceWbtc] = useState(null);
  const [getAllowanceUsdc1, setAllowanceUsdc1] = useState(null);
  const [getAllowanceWbtc1, setAllowanceWbtc1] = useState(null);
  const [sallowance1, setAllowance1] = useState(null);
  const [currentRount, setCurrentRount] = useState(null)
  const [currentTimer, setCurrentTimer] = useState(null)
  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [saleCurrentStaus, setSaleCurrentStaus] = useState(false);
  const [saleCurrentStaus1, setSaleCurrentStaus1] = useState(false);
  const [check, setCheck] = useState(false)
  const [amountInUsdt, setAmountInUsdt] = useState(null);
  const [niceName, setNicName] = useState(null)
  // console.log(niceName,'niceName');
  const [tab, setTab] = useState('home')
  const [tagImge, setTagImge] = useState('/assets/eth-icon.svg')
  const [pepePrice, setPepePrice] = useState(0)
  const [wbtcCurrentPrice, setWbtcCurrentPrice] = useState(null)
  // const [usdcCurrentPrice,setUsdcCurrentPrice]=useState(null)
  const [balaceOfTmi, setBalanceOfTomi] = useState(null)
  const [balaceOfUnii, setBalanceOfUnii] = useState(null)
  const [balaceOfDoppp, setBalanceOfDopp] = useState(null)
  const [balaceOfLinkk, setBalanceOfLinkk] = useState(null)
  const [balaceOfPepee, setBalanceOfPepee] = useState(null)
  const [balaceOfUsdc, setBalanceOfUsdc] = useState(null)
  const [balaceOfWbtc, setBalanceOfWbtc] = useState(null)
  const [tomiAllowance, setTomiAllwance] = useState(null)
  const [uniAllowance, setUniAllwance] = useState(null)
  const [linkAllowance, setLinkAllwance] = useState(null)
  const [dopAllowance, setDopAllwance] = useState(null)
  const [pepeAllowance, setPepeAllwance] = useState(null)
  const [tomiAllowance1, setTomiAllwance1] = useState(null)
  const [percent, setPercet] = useState(0)
  const [stakeIndex, setStakeIndux] = useState([])
  const [stakedAmount, setStakedAmount] = useState(0);
  const [multiplyPercentNumbere, setMultiplyPercentNumbere] = useState(0)
  const [discountedPriceee, setDiscountedPriceee] = useState(0)
  const [ethValueCalcualte, setethValueCalcualte] = useState(null)
  // const [limitObj, setLimitObj] = useState(null)
  // const [limitObjperc, setLimitObjperc] = useState(null)
  // const [gaugePosition,setGaugePosition] =useState(null);
  // console.log( stakedAmount,discountedPriceee,multiplyPercentNumbere,' Additional Tokens');


  const [show0, setShow0] = useState(false);

  const handleClos0 = async () => {
    setShow0(false)
    if (inputFeild && inputFeild1) {
      if (chainId === 1) {
        confirm();
      } else {
        let a = await setupNetwork()
        if (a) {
          confirm();
        }
      }
    } else {
    }
  };
  const handleShow0 = () => setShow0(true);


  const [show5, setShow5] = useState(false);
  const handleClose5 = () => {
    setShow5(false)
    localStorage.setItem('twittter', true)
  };
  const handleShow5 = () => setShow5(true);
  // console.log(getAllowanceWbtc ,'saleCurrentStaus',);
  // console.log(price, 'currentTime');
  useEffect(() => {
    //   if (limitObj == null) {
    //     let s = JSON.parse(objjjj)
    //     setLimitObj(s)
    //     let a = parseInt(s.buyingLimit)
    //     let b = parseInt(s.saleCount)
    //     let c = (b / a) * 100
    //     const clampedValue = Math.min(100, Math.max(0, c));
    //      setGaugePosition(`${clampedValue}%`)
    //     setLimitObjperc(c)
    //   }
    if (!modalTwitter) {
      handleShow5()
    }
  }, [])

  useEffect(() => {
    // capHandler()
    firstTimeData();
    firstTimeData11()
    uniPriceHandler()
    linkPriceHnadler()
    dopPriceHandler()
    saleStatusss()
    alowence();
    getPepePrice()
    getWbtcCurrentPrice()
    if (account) {
      balaceOf();
      firstTimeData1();
      balancerOftomi()
      balancerOfPepe()
      balancerOfUni()
      balancerOfDop()
      balancerOfLink()
      balancerOfUsdc()
      balancerOfWbtc()
      fetchStakingData()
      // StakeIndec()
      getGemsStakedAmount()

    } else {
      setBalanceeUsdt(null);
      setBlanceOfEth(null);
      setBalanceOfTomi(null)
      setBalanceOfUnii(null)
      setBalanceOfDopp(null)
      setBalanceOfLinkk(null)
      setBalanceOfPepee(null)
      setBalanceOfUsdc(null);
      setBalanceOfWbtc(null)
    }
  }, [account]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     firstTimeData();
  //     firstTimeData1();
  //   }, 30000); 

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [account]);

  // console.log(sallowance,'sajad',inputFeild);

  const saleStatusss = async () => {
    try {
      let b = await saleStatus()
      let b1 = await saleStatus1()
      if (b || b1) {
        // console.log(b1, 'noooor imad');
        // setCurrentRoundd(b)
        // roundData(b)
        setSaleCurrentStaus(b)
        setSaleCurrentStaus1(b1)
      }
    } catch (err) {
      console.log(err.message, 'nooor');
    }
  };


  const firstTimeData = async () => {
    setLoarder1(true)
    try {
      let b
      b = await cuurentRound()
      setRound(b)
      let price = 0
      if (b) {
        if (b == 1) {

          setMultiplyPercentNumbere(0.2)
          price = 0.2
        } else {
          setMultiplyPercentNumbere(0.07)
          price = 0.07
        }
        // console.log(b, 'cccccccc');
        // setCurrentRoundd(b)
        roundData(b, price)
      }
    } catch (err) {
      console.log(err.message, 'nooor');
      setLoarder1(false)
    }
  };
  // console.log(ethPricevalue,'ethPricevalue');
  const firstTimeData11 = async () => {
    try {
      let a = await ethPrice();
      if (a) {
        // console.log(a,'aaa');
        setEthPrice(a);
      }
    } catch (err) {
      console.log(err.message);
    }
  }
  // const uniPriceHandler = async () => {
  //   try {
  //     let a = await uniPrice();
  //     if (a) {
  //     setUniPrice(a);
  //     }
  //   } catch (err) {
  //     console.log(err.message,'aaa');
  //   }
  // };
  const uniPriceHandler = async () => {
    try {
      const config = {
        method: "get",
        url: `${API_URL}users/uni-price`
      };
      const postApiRes = await axios(config)
        .then(function (response) {
          let qw = response?.data?.data
          // setPepePrice(qw)
          setUniPrice(qw);
        })
    } catch (error) {
      console.log("coin market cap error::", error);
    }
  };
  const dopPriceHandler = async () => {
    try {
      const config = {
        method: "get",
        url: `${API_URL}users/dop-price`
      };
      const postApiRes = await axios(config)
        .then(function (response) {
          let qw = response?.data?.data
          // setPepePrice(qw)
          setDopPrice(qw);
        })
    } catch (error) {
      console.log("coin market cap error::", error);
    }
  };
  const linkPriceHnadler = async () => {
    try {
      let a = await linkPrice();
      if (a) {
        setlinkPrice(a);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const getPepePrice = async () => {
    try {
      const config = {
        method: "get",
        url: `${API_URL}users/pepe-price`
      };
      const postApiRes = await axios(config)
        .then(function (response) {
          // console.log(response,'response');
          let qw = response?.data?.data
          setPepePrice(qw)
        })
    } catch (error) {
      console.log("coin market cap error::", error);
    }
  };

  const firstTimeData1 = async () => {
    try {
      var balance = await web3.eth.getBalance(account);
      setBlanceOfEth(balance / 10 ** 18);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getWbtcCurrentPrice = async () => {
    try {
      let a = await wbtcPrice();
      if (a) {
        // console.log(a,'aaa');
        setWbtcCurrentPrice(a)
      }
    } catch (err) {
      console.log(err.message);
    }

  };

  const [show1, setShow1] = useState(false);
  const handleClose = () => {
    setInputField("");
    setInputField1("");
    setMyInTotlaPurchasess(0)
    setMyPsTotlaPurchasess(0)
    setShow1(false);
  };
  const handleShow = () => setShow1(true);
  const changeTag = () => {
    if (mainTag === "ETH") {
      setMainTag("USDT");
      setMainTag1("ETH");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)
      // }
    } else {
      setMainTag("ETH");
      setMainTag1("USDT");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)
    }
  };
  const changeTag1 = (e) => {
    if (e === "ETH") {
      discoundedPriceee(currentRoundd, Environment.eth)
      setMainTag("ETH");
      setTagImge('/assets/eth-icon.svg')
      // setMainTag1("ETH");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)
      // }
    } else if (e == "USDT") {
      discoundedPriceee(currentRoundd, Environment.busd)
      setMainTag("USDT");
      setTagImge('/assets/usdt.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)
    }
    else if (e == "USDC") {
      discoundedPriceee(currentRoundd, Environment.usdc)
      setMainTag("USDC");
      setTagImge('/assets/usdc.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)
    }
    else if (e == "WBTC") {
      discoundedPriceee(currentRoundd, Environment.wbtc)
      setMainTag("WBTC");
      setTagImge('/assets/wbtc.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)
    }
    else if (e == "PEPE") {
      discoundedPriceee(currentRoundd, Environment.tomi)
      setMainTag("PEPE");
      setTagImge('/assets/pepe.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)

    }
    else if (e == "DOP") {
      discoundedPriceee(currentRoundd, Environment.tomi)
      setMainTag("DOP");
      setTagImge('/assets/dop.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)

    }
    else if (e == "UNI") {
      discoundedPriceee(currentRoundd, Environment.tomi)
      setMainTag("UNI");
      setTagImge('/assets/uniswap.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)

    }
    else {
      discoundedPriceee(currentRoundd, Environment.tomi)
      setMainTag("LINK");
      setTagImge('/assets/link.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
      setMyInTotlaPurchasess(0)
      setMyPsTotlaPurchasess(0)

    }
  };
  const inputField1Handler = (e) => {
    setAmountInUsdt('')
    let value = e.target.value;
    if (mainTag === "ETH") {
      if (value) {
        let amount = parseFloat(((ethPricevalue * value) / price)).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setAmountInUsdt((ethPricevalue * value).toFixed(4))
        setInputField(value);
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        let aa = (ethPricevalue * value).toFixed(4)
        let vv = aa / discountedPriceee
        let cc = vv - parseFloat(amount1)?.toFixed(2)
        setethValueCalcualte(cc)
        // console.log(cc.toFixed,aa,vv,discountedPriceee, 'ccccc');

        // (parseFloat(inputFeild) * parseFloat(ethPricevalue) / parseFloat(discountedPriceee))- (parseFloat(inputFeild1))
        // ?.toFixed(2)

      } else {
        setInputField1("");
        setInputField("");
        setethValueCalcualte(0)
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    } else if (mainTag === "USDT") {
      if (value) {
        let amount = parseFloat(value / price).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setInputField(value);
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setAmountInUsdt(value)


        let aa = value
        let vv = aa / discountedPriceee
        let cc = vv - parseFloat(amount1)?.toFixed(2)
        setethValueCalcualte(cc)
      } else {
        setInputField1("");
        setInputField("");
        setethValueCalcualte(0)
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "USDC") {
      if (value) {
        let amount = parseFloat(value / price).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField(value);
        setAmountInUsdt(value)
        let aa = value
        let vv = aa / discountedPriceee
        let cc = vv - parseFloat(amount1)?.toFixed(2)
        setethValueCalcualte(cc)
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
        setethValueCalcualte(0)
      }
    }
    else if (mainTag === "WBTC") {
      if (value) {
        let amount = parseFloat(((wbtcCurrentPrice * value) / price)).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setAmountInUsdt((wbtcCurrentPrice * value).toFixed(4))
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField(value);
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "UNI") {
      if (value) {
        let amount = parseFloat(((uniPricevalue * value) / price)).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }

        setInputField1(parseFloat(amount1)?.toFixed(2));
        setAmountInUsdt((uniPricevalue * value).toFixed(4))
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField(value);

        let aa = (uniPricevalue * value).toFixed(4)
        let vv = aa / discountedPriceee
        let cc = vv - parseFloat(amount1)?.toFixed(2)
        setethValueCalcualte(cc)
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "LINK") {
      if (value) {

        let amount = parseFloat(((linkPricevalue * value) / price)).toFixed(6)
        // console.log(linkPricevalue,value,price,amount);
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        // console.log('aasas',linkPricevalue,value,price);
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setAmountInUsdt((linkPricevalue * value).toFixed(4))
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField(value);

        let aa = (linkPricevalue * value).toFixed(4)
        let vv = aa / discountedPriceee
        let cc = vv - parseFloat(amount1)?.toFixed(2)
        setethValueCalcualte(cc)
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "DOP") {
      if (value) {

        let amount = parseFloat(((dopPricevalue * value) / price)).toFixed(6)
        // console.log(dopPricevalue,value,price,amount);
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        // console.log('aasas',linkPricevalue,value,price);
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setAmountInUsdt((dopPricevalue * value).toFixed(4))
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField(value);

        let aa = (dopPricevalue * value).toFixed(4)
        let vv = aa / discountedPriceee
        let cc = vv - parseFloat(amount1)?.toFixed(2)
        setethValueCalcualte(cc)
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else {
      if (value) {
        let amount = parseFloat(((pepePrice * value) / price)).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        // console.log(amount,'aasas',linkPrice,value,price);
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setAmountInUsdt((pepePrice * value).toFixed(4))
        setInputField(value);

        let aa = (pepePrice * value).toFixed(4)
        let vv = aa / discountedPriceee
        let cc = vv - parseFloat(amount1)?.toFixed(2)
        setethValueCalcualte(cc)
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
  };
  const inputField1Handler1 = (e) => {

    let value = e.target.value;
    if (mainTag === "ETH") {
      if (value) {
        let amount = parseFloat((value) * price / ethPricevalue).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField1(parseFloat(value));

        // }
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }

    }
    else if (mainTag === "USDT") {
      if (value) {
        let amount = parseFloat(value * price).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField1(value);
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "USDC") {
      if (value) {
        let amount = parseFloat(value * price).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField1(value);

      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "WBTC") {
      if (value) {
        let amount = parseFloat((value) * price / wbtcCurrentPrice).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField1(parseFloat(value));


      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "UNI") {
      if (value) {
        let amount = parseFloat((value) * price / uniPricevalue).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField1(parseFloat(value));


      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "LINK") {
      if (value) {
        let amount = parseFloat((value) * price / linkPricevalue).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField1(parseFloat(value));


      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else if (mainTag === "DOP") {
      if (value) {
        let amount = parseFloat((value) * price / dopPricevalue).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)
        setInputField1(parseFloat(value));


      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
    else {
      if (value) {
        let amount = parseFloat((value) * price / pepePrice).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setInputField1(parseFloat(value));
        setMyInTotlaPurchasess(amount1 + inTotlaPurchasess)
        setMyPsTotlaPurchasess(amount1 + psTotlaPurchasess)

        // }
      } else {
        setInputField1("");
        setInputField("");
        setMyInTotlaPurchasess(0)
        setMyPsTotlaPurchasess(0)
      }
    }
  };
  const balaceOf = async () => {
    try {
      let balace = await balacefAccount(account);
      if (balace) {
        setBalanceeUsdt(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOftomi = async () => {
    try {
      let balace = await balacefAccountTomi(account);
      if (balace) {
        setBalanceOfTomi(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOfPepe = async () => {
    try {
      let balace = await balacefAccountPepe(account);
      if (balace) {
        setBalanceOfPepee(balace);
        // console.log(balace,'balace');
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOfUni = async () => {
    try {
      let balace = await balacefAccountUni(account);
      if (balace) {
        // console.log(balace,'balace');
        setBalanceOfUnii(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOfDop = async () => {
    try {
      let balace = await balacefAccountDop(account);
      if (balace) {
        // console.log(balace,'balace');
        setBalanceOfDopp(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOfLink = async () => {
    try {
      let balace = await balacefAccountLink(account);
      if (balace) {
        // console.log(balace,'balace');
        setBalanceOfLinkk(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOfUsdc = async () => {
    try {
      let balace = await balacefAccountUsdc(account);
      if (balace) {
        setBalanceOfUsdc(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOfWbtc = async () => {
    try {
      let balace = await balacefAccountWbtc(account);
      if (balace) {
        setBalanceOfWbtc(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const alowence = async () => {
    try {
      let r = await allowanceTpl();
      setAllowance(r);
      let w11 = await allowanceLink()
      setLinkAllwance(w11)
      let w134 = await allowanceDop()
      setDopAllwance(w134)
      let w22 = await allowancePepe()
      setPepeAllwance(w22)
      let w33 = await allowanceUniswape()
      setUniAllwance(w33)
      let usdcc = await allowanceUsdc()
      setAllowanceUsdc(usdcc)
      let w = await allowanceTomi()
      setTomiAllwance(w)
      let r1 = await allowanceTpl1();
      setAllowance1(r1);
      let da = await allowanceTomi1()
      setTomiAllwance1(da)
      let wbtcc = await allowanceWbtc()
      setAllowanceWbtc(wbtcc)
      let usdcc1 = await allowanceUsdc1()
      setAllowanceUsdc1(usdcc1)
      let wbtcc1 = await allowanceWbtc1()
      setAllowanceWbtc1(wbtcc1)

    } catch (err) {
      console.log(err, 'nooooer23');
    }
  };
  const handlerPop = async () => {
    if (stakedAmount >= 5555) {
      if (inputFeild && inputFeild1) {
        if (chainId === 1) {

          confirm();
        } else {
          let a = await setupNetwork()
          if (a) {
            confirm();
          }
        }
      } else {
      }
    } else {
      handleShow0()
    }
  };
  const confirm = async () => {
    setLoarder(true);
    let postApiRes = ''
    var config = ''
    if (role === 'institution') {
      if (mainTag == 'ETH') {
        config = {
          method: "post",
          url: `${API_URL}users/institutional-buy`,
          data: {
            accessCode: code,
            walletAddress: inputFeild5?.toString(),
            "country": countryBuyer,


          },
        };
      }
      else if (mainTag == 'USDC') {
        config = {
          method: "post",
          url: `${API_URL}users/institutional-buy-usdc`,
          data: {
            accessCode: code,
            walletAddress: inputFeild5.toString(),
            "country": countryBuyer,

          },
        };
      }
      else if (mainTag == 'WBTC') {
        config = {
          method: "post",
          url: `${API_URL}users/institutional-buy-wbtc`,
          data: {
            accessCode: code,
            walletAddress: inputFeild5.toString(),
            "country": countryBuyer,

          },
        };
      }

      else if (mainTag == 'USDT') {
        config = {
          method: "post",
          url: `${API_URL}users/institutional-buy-with-stable-coin`,
          data: {
            accessCode: code,
            walletAddress: inputFeild5.toString(),
            "country": countryBuyer,

          },
        };
      } else {
        config = {
          method: "post",
          url: `${API_URL}users/institutional-buy-with-tomi`,
          data: {
            accessCode: code,
            walletAddress: inputFeild5?.toString(),
            "country": countryBuyer,

          },
        };
      }

      postApiRes = await axios(config)
        .then(function (response) {
          return response?.data?.data;
        })
        .catch(function (error) {
          toast.error(error?.response?.data.message);
        });
    }
    else {
      if (mainTag == 'TOMI') {
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-tomi`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            "tokensAmount": inputFeild1,
            "unitPrice": price,
            email: email
            // buyingAmount: amountInUsdt,
          },
        }
      } else if (mainTag == 'ETH') {

        config = {
          method: "post",
          url: `${API_URL}users/buy`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            "tokensAmount": inputFeild,
            "unitPrice": ethPricevalue,
            email: email
            // buyingAmount: amountInUsdt,

          },
        }
      }
      else if (mainTag == 'WBTC') {
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-wbtc`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            "tokensAmount": inputFeild,
            "unitPrice": wbtcCurrentPrice,
            email: email

          },
        };
      }
      else if (mainTag == 'USDC') {
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-stable-coin-usdc`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            "tokensAmount": inputFeild,
            "unitPrice": 1,
            email: email

          },
        };
      }
      else if (mainTag == 'UNI') {
        // let pricee= uniPricevalue
        // pricee =parseFloat(pricee).toFixed(6)
        // let amount1 = Math.round((pricee) * 1e12) / 1e12;
        // let nd = web3.utils.toWei(amount1.toString(), "mwei");
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-tokens`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            // "price": nd,
            email: email,
            "tokenSelected": "UNI",
            "type": "TOKEN"
          },
        };
      }
      else if (mainTag == 'LINK') {
        // let pricee= uniPricevalue
        // pricee =parseFloat(pricee).toFixed(6)
        // let amount1 = Math.round((pricee) * 1e12) / 1e12;
        // let nd = web3.utils.toWei(amount1.toString(), "mwei");
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-tokens`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            // "price": pricee,
            email: email,
            "tokenSelected": "LINK",
            "type": "TOKEN"
          },
        };
      }
      else if (mainTag == 'PEPE') {
        // let pricee= uniPricevalue
        // pricee =parseFloat(pricee).toFixed(6)
        // let amount1 = Math.round((pricee) * 1e12) / 1e12;
        // let nd = web3.utils.toWei(amount1.toString(), "mwei");
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-tokens`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            // "price": nd,
            email: email,
            "tokenSelected": "PEPE",
            "type": "TOKEN"
          },
        };
      }
      else if (mainTag == 'DOP') {
        // let pricee= uniPricevalue
        // pricee =parseFloat(pricee).toFixed(6)
        // let amount1 = Math.round((pricee) * 1e12) / 1e12;
        // let nd = web3.utils.toWei(amount1.toString(), "mwei");
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-tokens`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            // "price": nd,
            email: email,
            "tokenSelected": "DOP",
            "type": "TOKEN"
          },
        };
      }

      else {
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-stable-coin`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            "country": countryBuyer,
            "tokensAmount": inputFeild,
            "unitPrice": 1,
            email: email
            // buyingAmount: amountInUsdt,
          },
        }
      };
      postApiRes = await axios(config)
        .then(function (response) {
          return response?.data?.data;
        })
        .catch(function (error) {
          toast.error(error?.response?.data.message);
        });
    }
    setLoarder(true);
    if (role === 'institution') {
      if (mainTag === "ETH") {
        try {
          if (postApiRes) {
            if (saleCurrentStaus1) {
              let b = blanceOfEth - parseFloat(inputFeild)
              let x = await purchaseEth1(parseFloat(inputFeild1), parseFloat(inputFeild), postApiRes, inputFeild5, b);
              if (x) {
                confirmInst(x, postApiRes)
                setLoarder(false);
                firstTimeData();
                firstTimeData11()
                firstTimeData1();
                balaceOf();
                alowence();
                // balaceOf1();
                // capHandler()
                handleShow();
              }
            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }
          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          toast.error("Transaction reverted:" + err.message);
        }
      } else if (mainTag === "USDT") {
        if (sallowance1 === 0) {
          try {
            let z = await approveTpl1(10000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseUsdt1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > sallowance1) {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let res = await approveTpl1(0, account);
                  if (res) {
                    let res11 = await approveTpl1(10000000, account);

                    if (res11) {
                      let q = await purchaseUsdt1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                      if (q) {
                        confirmInst(q, postApiRes)
                        setLoarder(false);
                        firstTimeData();
                        firstTimeData11()
                        firstTimeData1();
                        balaceOf();
                        alowence();
                        // balaceOf1();
                        // capHandler()
                        handleShow();
                      }
                    }
                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseUsdt1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
      else if (mainTag === "WBTC") {
        if (getAllowanceWbtc1 === 0) {
          try {
            let z = await approveTplwbtc1(10000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseWbtc1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > getAllowanceWbtc1) {
              if (postApiRes) {
                if (saleCurrentStaus1) {

                  let res11 = await approveTplwbtc1(10000000, account);

                  if (res11) {
                    let q = await purchaseWbtc1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                    if (q) {
                      confirmInst(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }

                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseWbtc1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
      else if (mainTag === "USDC") {
        if (getAllowanceUsdc1 === 0) {
          try {
            let z = await approveTplUsdc1(10000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseUsdc1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                    // capHandler()
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > getAllowanceUsdc1) {
              if (postApiRes) {
                if (saleCurrentStaus1) {

                  let res11 = await approveTplUsdc1(10000000, account);

                  if (res11) {
                    let q = await purchaseUsdc1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                    if (q) {
                      confirmInst(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }

                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseUsdc1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                    // capHandler()
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
      else {
        if (tomiAllowance1 === 0) {
          try {
            let z = await approveTomi1(10000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseTomi1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                    // capHandler()
                  }
                }



              } else {
                toast.error("Sale is Crrently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > tomiAllowance1) {
              if (postApiRes) {
                if (saleCurrentStaus1) {

                  let res11 = await approveTomi1(10000000, account);

                  if (res11) {
                    let q = await purchaseTomi1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                    if (q) {
                      confirmInst(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }
                  }



                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseTomi1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
    } else {
      if (mainTag === "ETH") {
        try {
          if (postApiRes) {
            if (saleCurrentStaus) {
              let b = blanceOfEth - parseFloat(inputFeild)
              // console.log(b,'balance',gasPrice/10**9);
              let x = await purchaseEth(parseFloat(inputFeild1), parseFloat(inputFeild), postApiRes, currentRoundd, b, stakeIndex);
              if (x) {
                confirmBuy(x, postApiRes)
                setLoarder(false);
                firstTimeData();
                firstTimeData11()
                firstTimeData1();
                balaceOf();
                alowence();
                // capHandler()
                // balaceOf1();
                handleShow();
              }
            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }
          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err.message, "errr");
          setLoarder(false);
          toast.error("Transaction reverted:" + err.message);
        }
      } else if (mainTag === "USDT") {
        if (sallowance === 0) {
          try {
            if (postApiRes) {
              if (saleCurrentStaus) {
                let z = await approveTpl(10000000, account);
                if (z) {
                  let q = await purchaseUsdt(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            console.log(err, "err");
            alowence();
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);

          }
        } else {
          try {
            if (inputFeild > sallowance) {
              if (postApiRes) {
                if (saleCurrentStaus) {
                  let res = await approveTpl(0, account);
                  if (res) {
                    let res11 = await approveTpl(10000000, account);

                    if (res11) {
                      let q = await purchaseUsdt(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                      if (q) {
                        confirmBuy(q, postApiRes)
                        setLoarder(false);
                        firstTimeData();
                        firstTimeData11()
                        firstTimeData1();
                        balaceOf();
                        alowence();
                        // capHandler()
                        // balaceOf1();
                        handleShow();
                      }
                    }
                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus) {
                  let q = await purchaseUsdt(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            console.log(err, "errr");
            setLoarder(false);
            alowence();
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
      else if (mainTag === "USDC") {
        // console.log(getAllowanceUsdc, 'sallowance');
        if (getAllowanceUsdc === 0) {
          try {
            if (postApiRes) {
              if (saleCurrentStaus) {
                let z = await approveTplUsdc(10000000, account);
                if (z) {
                  let q = await purchaseUsdc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    // capHandler()
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            console.log(err, "err");
            alowence();
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);

          }
        } else {
          try {
            if (inputFeild > getAllowanceUsdc) {
              if (postApiRes) {
                if (saleCurrentStaus) {
                  let res11 = await approveTplUsdc(10000000, account);

                  if (res11) {
                    let q = await purchaseUsdc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }
                  }



                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus) {
                  let q = await purchaseUsdc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            console.log(err, "errr");
            setLoarder(false);
            alowence();
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }


      else if (mainTag === "WBTC") {
        // console.log(sallowance, 'sallowance');
        if (getAllowanceWbtc === 0) {
          try {
            if (postApiRes) {
              if (saleCurrentStaus) {
                let z = await approveTplwbtc(10000000, account);
                if (z) {
                  let q = await purchaseWbtc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    // capHandler()
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            console.log(err, "err");
            alowence();
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);

          }
        } else {
          try {
            if (inputFeild > getAllowanceWbtc) {
              if (postApiRes) {
                if (saleCurrentStaus) {
                  let res11 = await approveTplwbtc(10000000, account);

                  if (res11) {
                    let q = await purchaseWbtc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }

                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus) {
                  let q = await purchaseWbtc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            console.log(err, "errr");
            setLoarder(false);
            alowence();
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }

      else if (mainTag === "LINK") {
        // console.log(mainTag,linkAllowance,'sdsdsds');
        if (linkAllowance === 0) {
          try {
            let z = await approveLink(10000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseLink(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > linkAllowance) {
              if (postApiRes) {
                if (saleCurrentStaus1) {

                  let res11 = await approveLink(10000000, account);

                  if (res11) {
                    let q = await purchaseLink(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }

                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseLink(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
      else if (mainTag === "PEPE") {
        if (pepeAllowance === 0) {
          try {
            let z = await approvePepe(1000000000000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchasePepe(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > pepeAllowance) {
              if (postApiRes) {
                if (saleCurrentStaus1) {

                  let res11 = await approvePepe(10000000000000000, account);

                  if (res11) {
                    let q = await purchasePepe(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }

                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchasePepe(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
      else if (mainTag === "DOP") {
        if (dopAllowance === 0) {
          try {
            let z = await approveDop(1000000000000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseDop(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > dopAllowance) {
              if (postApiRes) {
                if (saleCurrentStaus1) {

                  let res11 = await approveDop(10000000000000000, account);

                  if (res11) {
                    let q = await purchaseDop(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }

                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseDop(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
      else if (mainTag === "UNI") {
        if (uniAllowance === 0) {
          try {
            let z = await approveUnisawape(10000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseUniswape(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > uniAllowance) {
              if (postApiRes) {
                if (saleCurrentStaus1) {

                  let res11 = await approveUnisawape(10000000, account);

                  if (res11) {
                    let q = await purchaseUniswape(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }

                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseUniswape(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }

      else {
        if (tomiAllowance === 0) {
          try {
            if (postApiRes) {
              if (saleCurrentStaus) {
                let z = await approveTomi(10000000, account);
                if (z) {
                  let q = await purchaseTomi(inputFeild, postApiRes, currentRoundd, pepePrice, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    // capHandler()
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            console.log(err, "err");
            alowence();
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > tomiAllowance) {
              if (postApiRes) {
                if (saleCurrentStaus) {

                  let res11 = await approveTomi(10000000, account);

                  if (res11) {
                    let q = await purchaseTomi(inputFeild, postApiRes, currentRoundd, pepePrice, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // capHandler()
                      // balaceOf1();
                      handleShow();
                    }
                  }



                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus) {
                  let q = await purchaseTomi(inputFeild, postApiRes, currentRoundd, pepePrice, blanceOfEth, parseFloat(inputFeild1), stakeIndex);
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    // capHandler()
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            console.log(err, "errr");
            setLoarder(false);
            alowence();
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
    }


  };




  useEffect(() => {
    const interval = setInterval(() => {
      let now = ''
      let time = ''
      let diff = ''
      if (currentTime < new Date(parseInt(currentRount?.startTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount?.startTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
      } else if (currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount?.endTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
        // console.log(diff);
      }
      if (diff <= 0) {
        if (currentTime < new Date(parseInt(currentRount?.startTime))) {
          window.location.reload()
        }
        clearInterval(interval);
        setTimeshow(true)
        // setPrice(0)
        // setId(null)
        // setRound(null)
      }
      const days = Math.floor(diff / (60 * 60 * 24));
      const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((diff % (60 * 60)) / (60));
      const secs = Math.floor((diff % (60)));
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, [currentRount]);




  const userLogin1 = async (e) => {
    // setLoarder(true);
    discoundedPriceee(e, Environment?.eth)
    let b = tab === 'home' ? "sale-rounds/users" : "sale-rounds/users-nft"
    await axios
      .get(API_URL + b)
      .then(async (res) => {
        // setLoarder(false);
        let a = res.data.data
        // console.log(a, 'aaaa', e);
        a.map((i) => {
          if (e == i?.round && i.privateSale == "INCENTIV") {
            setNicName(i.nickName)

          }
        })

        setTimeout(() => {
          // console.log("Delayed for 1 second.");
          setLoarder1(false)
        }, "1000");
        // calucation(res.data.data)
        // console.log(res.data.data,'res.data.data');
      })
      .catch((err) => {
        console.log(err, "err");
        setLoarder1(false)
        // setLoarder(false);
      });
  };
  const calucation = (e, pppp) => {
    // console.log(e,'imad');
    let dummy = []
    let a = ''
    let indexx = ''
    let aaa = e.map((i, index) => {
      let tempLockedLending2 = ''
      if (currentTime > parseInt(i.startTime) && currentTime < parseInt(i.endTime)) {
        a = i
        indexx = index + 1
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)

      } else {
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)
      }

    })
    if (a) {
      // console.log('noooooooor', a);
      setPrice(a?.price / 10 ** 18)
      let b = a?.price / 10 ** 18
      let c = b * pppp
      let v = b - c
      setDiscountedPriceee(v)
      setCopyOfPrice(a?.price / 10 ** 18)
      setCurrentRount(a)
      setCurrentRoundd(indexx)
      userLogin1(indexx)
      // setPrice(value?.price/10**18)

    }
    else if (dummy?.length > 0) {
      dummy.sort((a, b) => {
        return a?.diffrence - b?.diffrence
      })
      // console.log(dummy,'dummydummy');
      setCurrentRount(dummy[0])
      setCurrentRoundd(dummy[0]?.currentround)
      setPrice(dummy[0]?.price / 10 ** 18)
      let b = dummy[0]?.price / 10 ** 18
      let c = b * pppp
      let v = b - c
      setDiscountedPriceee(v)
      setCopyOfPrice(dummy[0]?.price / 10 ** 18)
      userLogin1(dummy[0]?.currentround)
      // console.log(dummy,'dummy');
    }
    // console.log(dummy,'new dateee',a);
    if (role === 'institution') {
      setPrice(instiPrice)
    }
  }
  useEffect(() => {
    // console.log('usefffef',email,code);
    if (!email || !code) {
      history.push('/')
    }
  }, [])
  // useEffect(() => {
  //   //  console.log(chainId,'chainIdchainId');
  //   if (account) {
  //     if (chainId != 1) {
  //       setupNetwork()
  //     }
  //   }
  // }, [account, chainId])
  // console.log(ethPricevalue,'ethPricevalue');



  const roundData = async (e, pppp) => {
    // console.log(e, 'ccccccc');
    try {
      if (parseInt(e) <= 1) {
        // console.log('in if noor ', e);
        let c = ''
        if (tab === 'home') {
          // console.log(e,'noooor');
          c = await timer(e)
          // console.log(c,'in timer');
        } else {
          c = await timerNft(e)
        }

        if (c) {

          setCurrentRount(c)
          setPrice(c?.price / 10 ** 18)
          let b = c?.price / 10 ** 18
          let cc = b * pppp
          let v = b - cc
          setDiscountedPriceee(v)
          // console.log(e, multiplyPercentNumbere, v, 'ccccccc');
          setCopyOfPrice(c?.price / 10 ** 18)
          setCurrentRoundd(e)
          userLogin1(e)
          setTimeout(() => {
            // console.log("Delayed for 1 second.");
            setLoarder1(false)
          }, "1000");

          // setPrice(c.price/10**18)
          // console.log('nooor',c.price);
          // setCurrentTimer(c)
        }
      }
      else {
        let dummy = []
        // console.log(e, 'loooop bahis');
        for (let a = 1; a <= e; a++) {
          // console.log(a, 'loooop');
          let c = ''
          if (tab === 'home') {
            c = await timer(a)
            //  console.log('noor if  ',c);
          } else {
            // console.log('noor else  ',c);
            c = await timerNft(a)
          }
          dummy.push(c)

        }
        // console.log(dummy, 'loooop');
        calucation(dummy, pppp)


      }
    } catch (err) {
      console.log(err, 'erro in timer');
      setLoarder1(false)
    }
  }

  const inputField1Handler5 = async (e) => {
    var length = e.target.value.split(" ").join("").length;
    setInputField5(e.target.value)
    setCheck(false)
    if (length === 42) {
      let r = web3.utils.isAddress(e.target.value)
      if (r) {
        setCheck(true)

      } else {
        setCheck(false)
      }
    }
  }


  const confirmInst = async (data, api) => {
    // console.log(data, api, inputFeild5, price, inputFeild1, inputFeild, mainTag, 'noooooor');
    const config = {
      method: "post",
      url: `${API_URL}emails`,
      data: {
        "accessCode": code,
        "transactionHash": data?.transactionHash,
        "accessCodeRole": role,
        "by": account,
        "recipient": inputFeild5,
        "dopPurchased": inputFeild1,
        "price": price,
        "buyingAmount": parseFloat(inputFeild),
        "recipientEmail": email,
        "tokenSelected": mainTag,
        "country": countryBuyer,
        "ipAddress": ipBuyer,
      },

    };
    const postApiRes = await axios(config)
      .then(function (response) {
        // return response?.data?.data;
      })
      .catch(function (error) {
        // toast.error(error?.response?.data.message);
      });

  }
  const confirmBuy = async (dataaa, api) => {
    // console.log(data, api, inputFeild5, price, inputFeild1, inputFeild, mainTag, 'noooooor');
    let nereValue = parseFloat(inputFeild1) + parseInt(ethValueCalcualte?.toFixed(2))
    const config = {
      method: "post",
      url: `${API_URL}emails`,
      data: {
        "accessCode": code,
        "transactionHash": dataaa?.transactionHash,
        "accessCodeRole": role,
        "by": account,
        "dopPurchased": stakedAmount >= 5555 ? nereValue : inputFeild1,
        "buyingAmount": parseFloat(inputFeild),
        "recipientEmail": email,
        "tokenSelected": mainTag,
        "country": countryBuyer,
        "ipAddress": ipBuyer,
        "price": stakedAmount >= 5555 ? discountedPriceee?.toFixed(4) : price,
      },

    };
    const postApiRes = await axios(config)
      .then(function (response) {
        // return response?.data?.data;
      })
      .catch(function (error) {
        // toast.error(error?.response?.data.message);
      });

  }


  const tabHandler = (e) => {
    discoundedPriceee(currentRoundd, Environment.eth)
    setMainTag("ETH");
    setTagImge('/assets/eth-icon.svg')
    if (tab == 'home') {
      setTab('profile')
    } else {
      setTab('home')
    }
  }
  // console.log(price,'buy price');
  const discoundedPriceee = async (aa, e) => {
    // console.log(a,e,'a,e');
    try {
      let ab = await discountedPrice(aa, e)
      // console.log('buy',ab,copyOfPrice);
      if (role != 'institution') {
        if (ab > 0) {
          // console.log('if chala');
          setPrice(ab)
          let b = ab
          let c = b * multiplyPercentNumbere
          let v = b - c
          setDiscountedPriceee(v)
          setPercet(100 * ((copyOfPrice - ab) / copyOfPrice))
          setPriceCheck(true)

        } else {
          if (copyOfPrice) {
            setPriceCheck(false)
            setPrice(copyOfPrice)
            let b = copyOfPrice
            let c = b * multiplyPercentNumbere
            let v = b - c
            setDiscountedPriceee(v)
          }
        }
      }

    } catch (err) {
      console.log(err, 'discoundedPrice err');
    }
  }

  const witterHandler = () => {
    const weChatShareUrl = "https://x.com/Incentiv_net";

    // Open the WeChat share link in a new window
    window.open(weChatShareUrl, '_blank');
    handleClose5()

  }


  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleTooltipMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleTooltipMouseLeave = () => {
    setShowTooltip(false);
  };


  useEffect(() => {
    setInterval(() => {
      fetchStakingData()
    }, 30000);
  }, [])
  // const apiCheck = async (e) => {

  //   const data = {
  //     accessCode: code,
  //   };
  //   await axios
  //     .post(API_URL + "users/verify-code", data)
  //     .then(async (res) => {
  //       setLimitObj(res.data.data.limit)
  //       let a = parseInt(res.data.data.limit.buyingLimit)
  //       let b = parseInt(res.data.data.limit.saleCount)
  //       let c = (b / a) * 100
  //       const clampedValue = Math.min(100, Math.max(0, c));
  //       setGaugePosition(`${clampedValue}%`)
  //       setLimitObjperc(c)
  //       let dd = JSON.stringify(res.data.data.limit)
  //       localStorage?.setItem("oobjj", dd);
  //     })
  //     .catch((err) => {
  //       console.log(err, "err");

  //     });
  // }


  const fetchStakingData = async () => {
    // setLoarder1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
        stakeds(where: {isClaimed: false, staker: "${account}"}) {
          stakerStakeIndex
          transactionHash
          stakeEndTime
          staker
          isClaimed
          id
          blockTimestamp
          blockNumber
          amount
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/74755/staking-index/001",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        // console.log(response,response?.data?.data?.staked?.length, 'response?.data?.data?.investments mainn1 sddasasa');
        const resData = response?.data?.data?.stakeds;
        if (resData?.length > 0) {
          let a = resData.map((e) => { return e.stakerStakeIndex })
          // console.log(a, 'mainn1');
          let bcc = a?.sort();
          setStakeIndux(bcc)
          setStakingData(resData);
          const currentTime = moment();
          const endTimeStampInSeconds = parseInt(
            resData[resData?.length - 1]?.stakeEndTime
          );
          const endTimeStamp = moment(endTimeStampInSeconds * 1000); // Convert to milliseconds
          if (!endTimeStamp.isValid()) {
            setStakeEndTime(currentTime);
          } else {
            setStakeEndTime(endTimeStamp);
          }
          // setLoarder1(false)
        }
      })
      .catch(function (error) {
        // setLoarder1(false);
        console.log(error, "error onse?.data?.data?.investments ma");
      });
  };

  //   const StakeIndec=async()=>{
  //     const bal = await getGemsStakedIndex(account);
  //     console.log(bal, 'bal');
  //     if (bal !== null || bal !== undefined) {
  //       if(bal==0){
  //         setStakeIndux([])
  //       }else{
  //         setStakeIndux([parseInt(bal)-1])
  //       }

  //     }
  //  }
  // console.log(inputFeild, (ethPricevalue).toFixed(2), discountedPriceee, inputFeild1, 'ssd');

  const getGemsStakedAmount = async () => {
    const staked = await getGemsStaked(account);

    if (staked) {
      setStakedAmount(staked);
    }
  };

  // console.log('pepe',pepePrice,'uni',uniPricevalue,'link',linkPricevalue,'price',"eth",ethPricevalue,"dop",dopPricevalue);
  return (
    <>
      {loaderr1 && (
        <Loader />
      )}
      {loaderr && (
        <Loader text={"Please wait, your transaction is in process"} />
      )}
      <Navbar setShow={setShow} />
      <section className="main-banner">
        {/* <img
          src="\assets\homepage-bg.png"
          alt="img"
          className="img-fluid banner-bg"
        />
        <img
          src="\assets\mblviewbg.png"
          alt="img"
          className="img-fluid mblviewbanner-bg d-none"
        /> */}
        <div className="container">
          <div className="banner-content">
            <div className="timermain">

              <div className="timerleft">
                <div className="mainiconimg">
                  <img src="\assets\newincentivlogo.svg" alt="iconimginner" className="iconimginner" />
                </div>
                <div className="mainicontexts">
                  <h6 className="timerhead">INCENTIV</h6>
                  {role !== "institution" ?
                    <>
                      {currentTime < new Date(parseInt(currentRount?.startTime)) ?

                        <p className="timerpara"> {niceName} Starts in:</p>
                        :
                        <>
                          {
                            currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime)) ?


                              <p className="timerpara"> {niceName} Ends in:</p>
                              :
                              ""

                          }

                        </>

                      }

                    </>
                    :
                    ""
                  }
                </div>
              </div>
              {role !== "institution" ?
                <>
                  {timeshow ? (
                    <p className="timerended">Sale ended!</p>
                  ) : (
                    <div className="timerspans">
                      <div className="maintime">
                        <div className="innertimer">
                          <span className="innertimertext">{day ? day : 0}</span>
                        </div>
                        <p className="innertimepara">Days</p>
                      </div>
                      <div className="maintime">
                        <div className="innertimer">
                          <span className="innertimertext">{hour ? hour : 0}</span>
                        </div>
                        <p className="innertimepara">Hours</p>
                      </div>
                      <div className="maintime">
                        <div className="innertimer">
                          <span className="innertimertext">{min ? min : 0}</span>
                        </div>
                        <p className="innertimepara">Minutes</p>
                      </div>
                      <div className="maintime">
                        <div className="innertimer">
                          <span className="innertimertext">{sec ? sec : 0}</span>
                        </div>
                        <p className="innertimepara">Second</p>
                      </div>
                    </div>
                  )}
                </>
                :
                ""
              }

            </div>

            <div className="twice-content">
              <div className="upper-content">
                <div className="left">
                  <img
                    src="\assets\newincentivlogo.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="text">
                    <h6>INC</h6>
                    {/* <p>INC</p> */}
                  </div>
                </div>
                <div className="right">
                  {/* <h6>${tomi?.toFixed(2)}</h6>
                {change24hr1 > 0 ? (
                  <p>{change24hr1?.toFixed(2)}% (1d)</p>
                ) : (
                  <p className="red">{change24hr1?.toFixed(2)}% (1d)</p>
                )} */}
                  {
                    stakedAmount >= 5555 ?
                      <div className="right-text-content">
                        <div className="inner-left">
                          <h5>Special offer</h5>
                          <p className="special-para">You save {round == 1 ? '20' : "7"}%</p>
                        </div>
                        <div className="inner-right">
                          <h6> <h6>${discountedPriceee ? parseFloat(discountedPriceee)?.toFixed(5) : "0.000"} USD</h6></h6>
                          <p>${parseFloat(price).toFixed(5)}</p>
                        </div>
                      </div>
                      :
                      <h6>${price ? parseFloat(price)?.toFixed(5) : "0.000"} USD</h6>
                  }


                </div>
              </div>
              <div className="discount-offer">
                <img src="\discont-bg.png" alt="img" className="img-fluid discont-bg" />
                <div className="left-sidee">
                  <div className="upper-text"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_5653_2304)">
                      <path d="M23.9993 11.9997C23.9993 10.3037 23.1393 8.80866 21.8313 7.92666C22.1323 6.37866 21.6833 4.71366 20.4843 3.51366C19.2853 2.31466 17.6203 1.86566 16.0713 2.16666C15.1893 0.858665 13.6943 -0.00133514 11.9983 -0.00133514C10.3023 -0.00133514 8.80734 0.858665 7.92534 2.16666C6.37734 1.86566 4.71134 2.31466 3.51234 3.51366C2.31334 4.71266 1.86434 6.37766 2.16534 7.92666C0.857345 8.80866 -0.00265503 10.3037 -0.00265503 11.9997C-0.00265503 13.6957 0.857345 15.1907 2.16534 16.0727C1.86434 17.6207 2.31334 19.2867 3.51234 20.4857C4.71134 21.6847 6.37634 22.1337 7.92534 21.8327C8.80734 23.1407 10.3023 24.0007 11.9983 24.0007C13.6943 24.0007 15.1893 23.1407 16.0713 21.8327C17.6193 22.1337 19.2853 21.6847 20.4843 20.4857C21.6833 19.2867 22.1323 17.6217 21.8313 16.0727C23.1393 15.1907 23.9993 13.6957 23.9993 11.9997ZM7.99934 8.99966C7.99934 8.44767 8.44734 7.99966 8.99934 7.99966C9.55134 7.99966 9.99934 8.44767 9.99934 8.99966C9.99934 9.55166 9.55134 9.99966 8.99934 9.99966C8.44734 9.99966 7.99934 9.55166 7.99934 8.99966ZM10.7653 15.9997H8.43334L13.2333 7.99966H15.5653L10.7653 15.9997ZM14.9993 15.9997C14.4473 15.9997 13.9993 15.5517 13.9993 14.9997C13.9993 14.4477 14.4473 13.9997 14.9993 13.9997C15.5513 13.9997 15.9993 14.4477 15.9993 14.9997C15.9993 15.5517 15.5513 15.9997 14.9993 15.9997Z" fill="#FFC700" />
                    </g>
                    <defs>
                      <clipPath id="clip0_5653_2304">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                    <h6>Golden User</h6></div>
                  {stakingData?.length > 0 ?
                    <p>You are eligible to get upto {round == 1 ? '20' : "7"}% discount </p>
                    :
                    <p>Become a Golden User and receive upto {round == 1 ? '20' : "7"}% discount </p>
                  }


                </div>
                <div className="right-sidee">
                  {stakedAmount >= 5555 && stakingData?.length > 0 ?
                    <div className="see-plan">
                      <a href="/discount" className="btn-seeplan">see my plan</a>
                      <p>Plan expires on {moment(stakeEndTime)?.format("DD/MM/YY")}</p>
                    </div>
                    :
                    <a href="/discount" className="btn-discount">get discount</a>

                  }


                </div>
              </div>
              {/* <div className="availablemain">
                <h6 className="availablehead">Available Credit  <div className="tooltip-div">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip
                        id="smooth-tooltip"
                        onMouseEnter={handleTooltipMouseEnter}
                        onMouseLeave={handleTooltipMouseLeave}
                      >
                        <h5>Available Credit</h5>
                        <p>This indicates the available allocation for purchasing during the presale associated with this leader code.</p>
                      </Tooltip>
                    }
                    show={showTooltip}
                  >
                    <div
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ cursor: "pointer" }}
                    >
                      <svg style={{ marginTop: "-5px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M7.99967 1.33333C4.32634 1.33333 1.33301 4.32666 1.33301 7.99999C1.33301 11.6733 4.32634 14.6667 7.99967 14.6667C11.673 14.6667 14.6663 11.6733 14.6663 7.99999C14.6663 4.32666 11.673 1.33333 7.99967 1.33333ZM7.49967 5.33333C7.49967 5.05999 7.72634 4.83333 7.99967 4.83333C8.27301 4.83333 8.49967 5.05999 8.49967 5.33333V8.66666C8.49967 8.93999 8.27301 9.16666 7.99967 9.16666C7.72634 9.16666 7.49967 8.93999 7.49967 8.66666V5.33333ZM8.61301 10.92C8.57967 11.0067 8.53301 11.0733 8.47301 11.14C8.40634 11.2 8.33301 11.2467 8.25301 11.28C8.17301 11.3133 8.08634 11.3333 7.99967 11.3333C7.91301 11.3333 7.82634 11.3133 7.74634 11.28C7.66634 11.2467 7.59301 11.2 7.52634 11.14C7.46634 11.0733 7.41967 11.0067 7.38634 10.92C7.35301 10.84 7.33301 10.7533 7.33301 10.6667C7.33301 10.58 7.35301 10.4933 7.38634 10.4133C7.41967 10.3333 7.46634 10.26 7.52634 10.1933C7.59301 10.1333 7.66634 10.0867 7.74634 10.0533C7.90634 9.98666 8.09301 9.98666 8.25301 10.0533C8.33301 10.0867 8.40634 10.1333 8.47301 10.1933C8.53301 10.26 8.57967 10.3333 8.61301 10.4133C8.64634 10.4933 8.66634 10.58 8.66634 10.6667C8.66634 10.7533 8.64634 10.84 8.61301 10.92Z" fill="black" />
                      </svg>
                    </div>
                  </OverlayTrigger>
                </div></h6>
                <p className="availablepara"><span className="greynumber">${parseInt(limitObj?.saleCount)}</span>/${limitObj?.buyingLimit}</p>
                <ProgressBar now={limitObjperc} />
                <div className="Apppppppp">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={limitObjperc}
                    className="d-none"
                  />
                  <div className="gauge-container">
                    <div className="gauge">
                      <div className="gauge-indicator" style={{ left: gaugePosition }}></div>
                    </div>
                    <div className="gauge-labels">
                      <span>Low</span>
                      <span>Medium</span>
                      <span>High</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {role === 'institution' ?


              <div className="bottom-content">
                <h6 className="main-head">Buy</h6>
                <div className="parent-box">
                  <img
                    src="\assets\swap-arrow.svg"
                    alt="img"
                    className="img-fluid swap-arrow"
                  />
                  <div className="inner-box">  <div className="left">
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={tagImge}
                          alt="img"
                          className="img-fluid suxvsvsyucsusc"
                        />
                        <div className="text">
                          <h6>{mainTag}</h6>
                        </div>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('ETH')}>
                            <img
                              src="/assets/eth-icon.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            ETH
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDT')}>
                            <img
                              src="/assets/usdt.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            USDT
                          </a>
                        </li>

                        {/* <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('TOMI')}>
                                  <img
                                    src="\assets\tomi-icon-drop.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  TOMI
                                </a>
                              </li> */}
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDC')}>
                            <img
                              src="\assets\usdc.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            USDC
                          </a>
                        </li>
                        {/* <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('WBTC')}>
                            <img
                              src="\assets\wbtc.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            WBTC
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                    <div className="right">
                      <h6>
                        Balance:{" "}
                        <span>
                          {mainTag === "ETH"
                            ? blanceOfEth > 0
                              ? blanceOfEth
                                ?.toFixed(4)
                              : "0.000"

                            :
                            mainTag === "USDT" ?
                              balanceeUsdt > 0
                                ? balanceeUsdt
                                  ?.toFixed(4)
                                : "0.000"
                              :
                              mainTag === "USDC" ?
                                balaceOfUsdc > 0
                                  ? balaceOfUsdc
                                    ?.toFixed(4)
                                  : "0.000"
                                :
                                mainTag === "WBTC" ?
                                  balaceOfWbtc > 0
                                    ? balaceOfWbtc
                                      ?.toFixed(6)
                                    : "0.000"
                                  :

                                  balaceOfTmi > 0
                                    ? balaceOfTmi
                                      ?.toFixed(4)
                                    : "0.000"
                          }{" "}
                          {mainTag === "ETH" ? " ETH " : mainTag === "USDT" ? " USDT " : mainTag === "USDC" ? "USDC" : mainTag === "WBTC" ? "WBTC" : "TOMI"}
                        </span>{" "}
                      </h6>
                      {/* balanceeUsdt?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") */}
                      <input
                        onChange={inputField1Handler}
                        value={inputFeild}
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        placeholder="0.00"
                      />
                      {/* <p>0.00</p> */}
                    </div>
                  </div>
                  <div className="inner-box">
                    <div className="leftiii">
                      <img
                        src="\assets\newincentivlogo.svg"
                        alt="img"
                        className="img-fluid"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <div className="text">
                        <h6>INC</h6>
                      </div>
                    </div>
                    <div className="right">
                      <h6>
                        {/* Balance:{" "} */}
                        {/* <span style={{ color: "#fff" }}>
          {balacefAccountTomii > 0
            ? balacefAccountTomii
              ?.toFixed(4)
            : "0.000"}
        </span> */}
                      </h6>
                      <input
                        onChange={inputField1Handler1}
                        value={inputFeild1}
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        placeholder="0.00"
                      />
                      {/* <p>0.00</p> */}
                    </div>
                  </div>

                </div>
                {role === 'institution' &&
                  <div className="material-textfield" style={{ marginTop: "20px", marginBottom: "25px" }}>
                    <input
                      onChange={inputField1Handler5}
                      value={inputFeild5}
                      onWheel={(e) => e.target.blur()}
                      type="text"
                      placeholder="Enter only ERC20 wallet address"


                    />
                    <label>Enter INC recipient wallet address</label>
                  </div>
                }
                <div className="bottom-text">
                  {/* <div className="inner-text">
<p>Receive:</p>
<h6>
{inputFeild1
  ? inputFeild1
    ?.toFixed(3)
  : "0.00"}{" "}
TOMI
</h6>
</div>
<div className="inner-text">
<p>+{aaa / 10}% Bonus:</p>
<h6>
{inputFeild1
  ? ((inputFeild1 * (aaa / 10)) / 100)
    ?.toFixed(3)
  : "0.00"}{" "}
TOMI
</h6>
</div> */}
                  <div className="inner-text-bold">
                    <h6>Total:</h6>
                    <h6>
                      {inputFeild1 && inputFeild
                        ? parseFloat((inputFeild1))
                          ?.toFixed(3)
                        : "0.00"}{" "}
                      INC
                    </h6>
                  </div>
                  {/* <div className="inner-text">
                    <p>Unlock Date:</p>
                    <h6>{date}</h6>
                    <h6>Q1,2024</h6>
                  </div> */}
                </div>
                {account ? (

                  <>
                    {role !== "institution" ?
                      <>
                        {mainTag === "ETH" ? (
                          <>
                            {inputFeild == "" ||
                              inputFeild == null || inputFeild <= 0 ||
                              blanceOfEth < inputFeild ? (
                              <>
                                <button className=" btn-buy">Buy INC </button>
                              </>
                            ) : (
                              <button className="newClasss" onClick={handlerPop}>
                                Buy INC{" "}
                              </button>
                            )}
                          </>
                        ) :
                          <>
                            {mainTag === "USDT" ?
                              (
                                <>
                                  {inputFeild == "" ||
                                    inputFeild == null || inputFeild <= 0 ||
                                    balanceeUsdt < inputFeild ? (
                                    <>
                                      <button className=" btn-buy">Buy INC </button>
                                    </>
                                  ) : (
                                    <button className="newClasss" onClick={handlerPop}>
                                      Buy INC{" "}
                                    </button>
                                  )}
                                </>
                              )
                              :
                              (
                                <>
                                  {inputFeild == "" ||
                                    inputFeild == null || inputFeild <= 0 ||
                                    balancerOftomi < inputFeild ? (
                                    <>
                                      <button className=" btn-buy">Buy INC </button>
                                    </>
                                  ) : (
                                    <button className="newClasss" onClick={handlerPop}>
                                      Buy INC{" "}
                                    </button>
                                  )}
                                </>
                              )


                            }
                          </>}
                      </>
                      :
                      <>
                        {check ?
                          <>
                            {inMaxxCap >= inMyTotlaPurchasess ?
                              <>
                                {mainTag === "ETH" ? (
                                  <>
                                    {inputFeild == "" ||
                                      inputFeild == null || inputFeild <= 0 ||
                                      blanceOfEth < inputFeild ? (
                                      <>
                                        <button className=" btn-buy">Buy INC </button>
                                      </>
                                    ) : (
                                      <button className="newClasss" onClick={handlerPop}>
                                        Buy INC{" "}
                                      </button>
                                    )}
                                  </>
                                ) :
                                  <>
                                    {mainTag == "USDT" ?
                                      (
                                        // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                        <>
                                          {inputFeild == "" ||
                                            inputFeild == null || inputFeild <= 0 ||
                                            balanceeUsdt < inputFeild ? (
                                            <button className=" btn-buy">Buy INC</button>
                                          ) : (
                                            <button className="newClasss" onClick={handlerPop}>
                                              Buy INC{" "}
                                            </button>
                                          )}
                                        </>
                                      )
                                      :
                                      <>
                                        {
                                          mainTag == "USDC" ?
                                            (
                                              // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                              <>
                                                {inputFeild == "" ||
                                                  inputFeild == null || inputFeild <= 0 ||
                                                  balaceOfUsdc < inputFeild ? (
                                                  <button className=" btn-buy">Buy INC</button>
                                                ) : (
                                                  <button className="newClasss" onClick={handlerPop}>
                                                    Buy INC{" "}
                                                  </button>
                                                )}
                                              </>
                                            )
                                            :
                                            <>
                                              {mainTag == "WBTC" ?
                                                (
                                                  // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                                  <>
                                                    {inputFeild == "" ||
                                                      inputFeild == null || inputFeild <= 0 ||
                                                      balaceOfWbtc < inputFeild ? (
                                                      <button className=" btn-buy">Buy INC</button>
                                                    ) : (
                                                      <button className="newClasss" onClick={handlerPop}>
                                                        Buy INC{" "}
                                                      </button>
                                                    )}
                                                  </>
                                                )
                                                :
                                                (
                                                  // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                                  <>
                                                    {inputFeild == "" ||
                                                      inputFeild == null || inputFeild <= 0 ||
                                                      balaceOfTmi < inputFeild ? (
                                                      <button className=" btn-buy">Buy INC</button>
                                                    ) : (
                                                      <button className="newClasss" onClick={handlerPop}>
                                                        Buy INC{" "}
                                                      </button>
                                                    )}
                                                  </>
                                                )

                                              }
                                            </>

                                        }
                                      </>
                                    }
                                  </>}
                              </>
                              :
                              <button className=" btn-buy">Buy INC</button>

                            }
                          </>
                          :
                          <button className=" btn-buy">Buy INC</button>

                        }
                      </>
                      // <button className=" newClasss" onClick={handlerPop}>Buy Do </button>

                    }
                  </>
                ) : (
                  <button className="btn-buy" onClick={() => setShow(true)}>
                    Buy INC
                  </button>
                )}
              </div>


              :

              <div className="custom-tabs">
                <Tabs
                  defaultActiveKey={tab}
                  id="uncontrolled-tab-example"
                  onSelect={tabHandler}>
                  <Tab eventKey='home' title="Buy Token">
                    <div className="bottom-content">
                      <h6 className="main-head">Buy</h6>
                      <div className="parent-box">
                        <img
                          src="\assets\swap-arrow.svg"
                          alt="img"
                          className="img-fluid swap-arrow"
                        />
                        <div className="inner-box">  <div className="left">
                          <div class="dropdown">
                            <button
                              class="dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={tagImge}
                                alt="img"
                                className="img-fluid suxvsvsyucsusc"
                              />
                              <div className="text">
                                <h6>{mainTag}</h6>
                              </div>
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('ETH')}>
                                  <img
                                    src="/assets/eth-icon.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  ETH
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDT')}>
                                  <img
                                    src="/assets/usdt.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  USDT
                                </a>
                              </li>
                              {/* <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('TOMI')}>
                                  <img
                                    src="\assets\tomi-icon-drop.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  TOMI
                                </a>
                              </li> */}
                              <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDC')}>
                                  <img
                                    src="\assets\usdc.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  USDC
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('LINK')}>
                                  <img
                                    src="\assets\link.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  LINK
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('PEPE')}>
                                  <img
                                    src="\assets\pepe.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  PEPE
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('UNI')}>
                                  <img
                                    src="\assets\uniswap.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  UNI
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('DOP')}>
                                  <img
                                    src="\assets\dop.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  DOP
                                </a>
                              </li>
                              {/* <li>
                                <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('WBTC')}>
                                  <img
                                    src="\assets\wbtc.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />{" "}
                                  WBTC
                                </a>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                          <div className="right">
                            <h6>
                              Balance:{" "}
                              <span>
                                {mainTag === "ETH"
                                  ? blanceOfEth > 0
                                    ? blanceOfEth
                                      ?.toFixed(4)
                                    : "0.000"

                                  :
                                  mainTag === "USDT" ?
                                    balanceeUsdt > 0
                                      ? balanceeUsdt
                                        ?.toFixed(4)
                                      : "0.000"
                                    :
                                    mainTag === "USDC" ?
                                      balaceOfUsdc > 0
                                        ? balaceOfUsdc
                                          ?.toFixed(4)
                                        : "0.000"
                                      :
                                      mainTag === "WBTC" ?
                                        balaceOfWbtc > 0
                                          ? balaceOfWbtc
                                            ?.toFixed(6)
                                          : "0.000"
                                        :
                                        mainTag === "UNI" ?
                                          balaceOfUnii > 0
                                            ? balaceOfUnii
                                              ?.toFixed(6)
                                            : "0.000"
                                          :
                                          mainTag === "LINK" ?
                                            balaceOfLinkk > 0
                                              ? balaceOfLinkk
                                                ?.toFixed(6)
                                              : "0.000"
                                            :
                                            mainTag === "PEPE" ?
                                              balaceOfPepee > 0
                                                ? balaceOfPepee
                                                  ?.toFixed(6)
                                                : "0.000"
                                              :
                                              mainTag === "DOP" ?
                                                balaceOfDoppp > 0
                                                  ? balaceOfDoppp
                                                    ?.toFixed(6)
                                                  : "0.000"
                                                :

                                                balaceOfTmi > 0
                                                  ? balaceOfTmi
                                                    ?.toFixed(4)
                                                  : "0.000"
                                }{" "}
                                {mainTag === "ETH" ? " ETH " : mainTag === "USDT" ? " USDT " : mainTag === "USDC" ? "USDC" : mainTag === "WBTC" ? "WBTC" : mainTag === "UNI" ? "UNI" : mainTag === "LINK" ? "LINK" : mainTag === "PEPE" ? "PEPE" : mainTag === "DOP" ? "DOP" : "TOMI"}
                              </span>{" "}
                            </h6>
                            {/* balanceeUsdt?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") */}
                            <input
                              onChange={inputField1Handler}
                              value={inputFeild}
                              onWheel={(e) => e.target.blur()}
                              type="number"
                              placeholder="0.00"
                            />
                            {/* <p>0.00</p> */}
                          </div>
                        </div>
                        <div className="inner-box">
                          <div className="leftiii">
                            <img
                              src="\assets\newincentivlogo.svg"
                              alt="img"
                              className="img-fluid"
                              style={{ width: "30px", height: "30px" }}
                            />
                            <div className="text">
                              <h6>INC</h6>
                            </div>
                          </div>
                          <div className="right">
                            <h6>
                              {/* Balance:{" "} */}
                              {/* <span style={{ color: "#fff" }}>
{balacefAccountTomii > 0
? balacefAccountTomii
?.toFixed(4)
: "0.000"}
</span> */}
                            </h6>
                            <input
                              onChange={inputField1Handler1}
                              value={inputFeild1}
                              onWheel={(e) => e.target.blur()}
                              type="number"
                              placeholder="0.00"
                            />
                            {/* <p>0.00</p> */}
                          </div>
                        </div>

                      </div>
                      {/* {parseFloat(amountInUsdt) + limitObj?.saleCount > limitObj?.buyingLimit ?
                        <p className="error-mesg-show">You can not purchase more than the available credit limit.</p>
                        :
                        ""

                      } */}
                      {role === 'institution' &&
                        <div className="material-textfield" style={{ marginTop: "5px", marginBottom: "25px" }}>
                          <input
                            onChange={inputField1Handler5}
                            value={inputFeild5}
                            onWheel={(e) => e.target.blur()}
                            type="text"
                            placeholder="Enter only ERC20 wallet address"


                          />
                          <label>Enter INC recipient wallet address</label>
                        </div>
                      }
                      <div className="bottom-text">
                        {/* <div className="inner-text">
<p>Receive:</p>
<h6>
{inputFeild1
? inputFeild1
?.toFixed(3)
: "0.00"}{" "}
TOMI
</h6>
</div>
<div className="inner-text">
<p>+{aaa / 10}% Bonus:</p>
<h6>
{inputFeild1
? ((inputFeild1 * (aaa / 10)) / 100)
?.toFixed(3)
: "0.00"}{" "}
TOMI
</h6>
</div> */}
                        {/* <div className="inner-text-bold">
                          <h6>sub Total</h6>
                          <h6>
                            {inputFeild1 && inputFeild
                              ? parseFloat((inputFeild1))
                                ?.toFixed(3)
                              : "0.00"}{" "}
                            INC
                          </h6>
                        </div> */}
                        {/* <div className="inner-text-bold inner-text-bolddddd">
                          <div className="twice-text">
                            <h6>{round == 1 ? '20' : "7"}% Additional Tokens:</h6>
                            <p>For Golden Users </p>
                          </div>
                          <h6>
                            {stakedAmount >=  5555 ?
                              <>
                                {inputFeild1 && inputFeild
                                  ?
                                  ethValueCalcualte.toFixed(2)
                                  :
                                  "0.00"}
                              </>
                              :
                              0.00
                              // (parseFloat(inputFeild) / discountedPriceee) - parseFloat((inputFeild1))
                            }
                            {" "}INC
                          </h6>
                        </div> */}
                        <span className="border-set-custom"></span>
                        <div className="inner-text-bold">
                          <h6>Total:</h6>
                          <h6>
                            {stakedAmount >= 5555 ?
                              <>
                                {inputFeild1 && inputFeild
                                  ?
                                  parseFloat(inputFeild1) + parseInt(ethValueCalcualte?.toFixed(2))
                                  :
                                  "0.00"}
                              </>
                              :
                              <>
                                {inputFeild1 && inputFeild
                                  ? parseFloat((inputFeild1))
                                    ?.toFixed(3)
                                  : "0.00"}
                              </>
                            }
                            {" "}INC
                          </h6>
                        </div>
                        {/* <div className="inner-text-bold">
                          <h6>Unlocks On:</h6>
                          <h6>
                            Q1,2024
                          </h6>
                        </div> */}

                        {/* <div className="inner-text">
                          <p>Unlock Date:</p>
                          <h6>{date}</h6>
                          <h6>Q1,2024</h6>
                        </div> */}
                      </div>
                      {account ? (

                        <>
                          {!timeshow && currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime)) ?
                            <>
                              {role !== "institution" ?
                                <>
                                  {/* {psMaxxCap>=psmyTotlaPurchasess? */}
                                  <>
                                    {mainTag === "ETH" ? (
                                      <>
                                        {inputFeild == "" ||
                                          inputFeild == null || inputFeild <= 0 ||
                                          blanceOfEth < inputFeild ? (
                                          <>
                                            <button className=" btn-buy">Buy INC </button>
                                          </>
                                        ) : (
                                          <button className="newClasss" onClick={handlerPop}>
                                            Buy INC{" "}
                                          </button>
                                        )}
                                      </>
                                    ) :
                                      <>
                                        {mainTag == "USDT" ?
                                          (
                                            // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                            <>
                                              {inputFeild == "" ||
                                                inputFeild == null || inputFeild <= 0 ||
                                                balanceeUsdt < inputFeild ? (
                                                <button className=" btn-buy">Buy INC</button>
                                              ) : (
                                                <button className="newClasss" onClick={handlerPop}>
                                                  Buy INC{" "}
                                                </button>
                                              )}
                                            </>
                                          )
                                          :
                                          <>
                                            {
                                              mainTag == "USDC" ?
                                                (
                                                  // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                                  <>
                                                    {inputFeild == "" ||
                                                      inputFeild == null || inputFeild <= 0 ||
                                                      balaceOfUsdc < inputFeild ? (
                                                      <button className=" btn-buy">Buy INC</button>
                                                    ) : (
                                                      <button className="newClasss" onClick={handlerPop}>
                                                        Buy INC{" "}
                                                      </button>
                                                    )}
                                                  </>
                                                )
                                                :
                                                <>
                                                  {mainTag == "WBTC" ?
                                                    (
                                                      // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                                      <>
                                                        {inputFeild == "" ||
                                                          inputFeild == null || inputFeild <= 0 ||
                                                          balaceOfWbtc < inputFeild ? (
                                                          <button className=" btn-buy">Buy INC </button>
                                                        ) : (
                                                          <button className="newClasss" onClick={handlerPop}>
                                                            Buy INC{" "}
                                                          </button>
                                                        )}
                                                      </>
                                                    )
                                                    :
                                                    <>
                                                      {mainTag == "LINK" ?

                                                        <>
                                                          {inputFeild == "" ||
                                                            inputFeild == null || inputFeild <= 0 ||
                                                            balaceOfLinkk < inputFeild ? (
                                                            <button className=" btn-buy">Buy INC </button>
                                                          ) : (
                                                            <button className="newClasss" onClick={handlerPop}>
                                                              Buy INC{" "}
                                                            </button>
                                                          )}
                                                        </>
                                                        :
                                                        <>
                                                          {mainTag == "PEPE" ?

                                                            <>
                                                              {inputFeild == "" ||
                                                                inputFeild == null || inputFeild <= 0 ||
                                                                balaceOfPepee < inputFeild ? (
                                                                <button className=" btn-buy">Buy INC </button>
                                                              ) : (
                                                                <button className="newClasss" onClick={handlerPop}>
                                                                  Buy INC{" "}
                                                                </button>
                                                              )}
                                                            </>
                                                            :
                                                            <>
                                                              {mainTag == "UNI" ?

                                                                <>
                                                                  {inputFeild == "" ||
                                                                    inputFeild == null || inputFeild <= 0 ||
                                                                    balaceOfUnii < inputFeild ? (
                                                                    <button className=" btn-buy">Buy INC </button>
                                                                  ) : (
                                                                    <button className="newClasss" onClick={handlerPop}>
                                                                      Buy INC{" "}
                                                                    </button>
                                                                  )}
                                                                </>
                                                                : <>
                                                                  {mainTag == "DOP" ?
                                                                    <>
                                                                      {inputFeild == "" ||
                                                                        inputFeild == null || inputFeild <= 0 ||
                                                                        balaceOfDoppp < inputFeild ? (
                                                                        <button className=" btn-buy">Buy INCsdsfdf </button>
                                                                      ) : (
                                                                        <button className="newClasss" onClick={handlerPop}>
                                                                          Buy INCsdfd{" "}
                                                                        </button>
                                                                      )}
                                                                    </>
                                                                    :
                                                                    (
                                                                      // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                                                      <>
                                                                        {inputFeild == "" ||
                                                                          inputFeild == null || inputFeild <= 0 ||
                                                                          balaceOfDoppp < inputFeild ? (
                                                                          <button className=" btn-buy">Buy INC</button>
                                                                        ) : (
                                                                          <button className="newClasss" onClick={handlerPop}>
                                                                            Buy INC{" "}
                                                                          </button>
                                                                        )}
                                                                      </>
                                                                    )


                                                                  }
                                                                </>
                                                              }
                                                            </>
                                                          }
                                                        </>
                                                      }
                                                    </>
                                                    // (
                                                    //   // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                                    //   <>
                                                    //     {inputFeild == "" ||
                                                    //       inputFeild == null || inputFeild <= 0 ||
                                                    //       balaceOfTmi < inputFeild ? (
                                                    //       <button className=" btn-buy">Buy INC</button>
                                                    //     ) : (
                                                    //       <button className="newClasss" onClick={handlerPop}>
                                                    //         Buy INC{" "}
                                                    //       </button>
                                                    //     )}
                                                    //   </>
                                                    // )

                                                  }
                                                </>

                                            }
                                          </>
                                        }
                                      </>}
                                  </>
                                  {/* :
                        <button className=" btn-buy">Buy GEMS</button>
                        } */}
                                </>

                                :
                                <>
                                  {mainTag === "ETH" ? (
                                    <>
                                      {check ? <>
                                        {inputFeild == "" ||
                                          inputFeild == null || inputFeild <= 0 ||
                                          blanceOfEth < inputFeild ? (
                                          <>
                                            <button className=" btn-buy">Buy INC{check}</button>
                                          </>
                                        ) : (
                                          <button className="newClasss" onClick={handlerPop}>
                                            Buy INC{" "}
                                          </button>
                                        )}
                                      </>
                                        :
                                        <button className=" btn-buy">Buy INC</button>
                                      }

                                    </>
                                  ) : (
                                    // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                                    <>
                                      {check ? <>
                                        {inputFeild == "" ||
                                          inputFeild == null || inputFeild <= 0 ||
                                          balanceeUsdt < inputFeild ? (
                                          <>
                                            <button className=" btn-buy">Buy INC</button>
                                          </>
                                        ) : (
                                          <button className="newClasss" onClick={handlerPop}>
                                            Buy INC{" "}
                                          </button>
                                        )}
                                      </>
                                        :
                                        <button className=" btn-buy">Buy INC</button>
                                      }
                                    </>
                                  )}
                                </>
                                // <button className=" newClasss" onClick={handlerPop}>Buy GEMS </button>

                              }
                            </>
                            :
                            <button className="btn-buy">
                              Buy INC
                            </button>
                          }
                        </>
                      ) : (
                        <button className="btn-buy" onClick={() => setShow(true)}>
                          Buy INC
                        </button>
                      )}

                    </div>
                  </Tab>
                  <Tab eventKey='profile' title="Buy NFTs">
                    <DopNft psMaxxCap={psMaxxCap} psmyTotlaPurchasess={psmyTotlaPurchasess} timeshow={timeshow} balaceOfWbtc={balaceOfWbtc} balaceOfUsdc={balaceOfUsdc} getAllowanceWbtc={getAllowanceWbtc} getAllowanceUsdc={getAllowanceUsdc} setShow={setShow} price={price} wbtcCurrentPrice={wbtcCurrentPrice} ethPricevalue={ethPricevalue} code={code} setLoarder={setLoarder} saleCurrentStaus={saleCurrentStaus} currentRoundd={currentRoundd} blanceOfEth={blanceOfEth} balanceeUsdt={balanceeUsdt} pepePrice={pepePrice} tomiAllowance={tomiAllowance} allowanceTpl={allowanceTpl} balaceOfTmi={balaceOfTmi} alowence={alowence} sallowance={sallowance} discoundedPriceee={discoundedPriceee} tab={tab} setMyPsTotlaPurchasess={setMyPsTotlaPurchasess} psTotlaPurchasess={psTotlaPurchasess} stakeIndex={stakeIndex} stakedAmount={stakedAmount} discountedPriceee={discountedPriceee} round={round} linkPricevalue={linkPricevalue} uniPricevalue={uniPricevalue} pepeAllowance={pepeAllowance} linkAllowance={linkAllowance} uniAllowance={uniAllowance} balaceOfLinkk={balaceOfLinkk} balaceOfPepee={balaceOfPepee} balaceOfUnii={balaceOfUnii} dopAllowance={dopAllowance} balaceOfDoppp={balaceOfDoppp} dopPricevalue={dopPricevalue} />
                  </Tab>
                </Tabs>
              </div>



            }

          </div>
        </div>
      </section>
      <Modal
        className="connectwallet-modal buy-modal"
        show={show1}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>INC Purchased</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\purchased.svg" alt="img" className="img-fluid" />
            <p>
              You have successfully purchased{" "} <br />
              <span>
                {stakedAmount >= 5555 ?
                  <>
                    {inputFeild1 && inputFeild
                      ?
                      parseFloat(inputFeild1) + parseInt(ethValueCalcualte?.toFixed(2))
                      :
                      "0.00"}
                  </>
                  :
                  <>
                    {inputFeild1 && inputFeild
                      ? parseFloat((inputFeild1))
                        ?.toFixed(3)
                      :
                      "0.00"}
                  </>
                }
                {" "}
                INC </span> for     <span> {inputFeild} {mainTag === "ETH" ? " ETH " : mainTag === "USDT" ? " USDT " : mainTag === "TOMI" ? "TOMI" : mainTag === "USDC" ? 'USDC' : mainTag === "LINK" ? 'LINK' : mainTag === "UNI" ? 'UNI' : mainTag === "PEPE" ? 'PEPE' : mainTag === "DOP" ? 'DOP' : "WBTC"} </span>
              {/* You can claim your DOP on <span>Q1,2024</span> */}
            </p>
            <button className="btn-common" onClick={handleClose}>
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        className="connectwallet-modal followusonx-modal"
        show={show5}
        onHide={handleClose5}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Follow Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buydopnft-modal buynft-modal2">
            <img src="\assets\followx.svg" alt="img" className='img-fluid' />
            <h4>Follow INCENTIV on X</h4>
            <p className="paracolorset">Follow incentiv on X to stay updated with news and announcements </p>
          </div>
          <div className="twice-btnsss">
            <button onClick={witterHandler} className='btn-proceed sscscscscscsc'>Follow INCENTIV on X</button>
          </div>
        </Modal.Body>
      </Modal>



      <Modal
        className="stakingmodal premiumstake-modal fornewbackdrop"
        show={show0}
        onHide={handleClos0}
        centered
        backdrop={false}
        keyboard={false}
      >
        <Modal.Body>
          <div className="stakingmodal">
            <div className="inner-content newheightcontent">
              <img
                src="\assets\premium\modalbg.png"
                alt="img"
                className="img-fluid modalbg-stake modalbgdnoneformbl"
              />
              <img
                src="/assets/nemgemsmodalbgmbl.png"
                alt="img"
                className="img-fluid modalbg-stake d-none newmodalbgimg"
              />
              <img
                src="\assets\premium\goldenuser.svg"
                alt="img"
                className="img-fluid"
              />
              <h5 className="newpara">
                Become a Golden User and <br /> receive <span className="newboldtext"> {round == 1 ? '20' : "7"}% discount </span>
              </h5>
            </div>
            {/* <div className="d-flex justify-content-center mb-4">
                            <p className="text-danger ">{walletError}</p>
                        </div> */}
            <div className="stakingbtns directionchange">
              <button
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                  handleClos0();
                }}
                className="cancelbtn"
              >
                Continue without Discount
              </button>
              <button onClick={() => {
                history.push("/discount")
              }} className="confirmbtn">Get the Discount</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
