import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { wdtcContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const BalacefAccountWbtc = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress1 = Environment.wbtc;
  const contract = wdtcContract(tokenAddress1, web3);
  const balacefAccountWbtc = useCallback(
    async () => {
      try {
        const buy = await contract.methods.balanceOf(account).call();
        // console.log("buy5555", buy)
        return buy/10**8;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { balacefAccountWbtc:balacefAccountWbtc };
};
export default BalacefAccountWbtc;