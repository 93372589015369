import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login/Login';
import Claim from './components/Claim/Claim';
import useEagerConnect from "./hooks/useEagerConnect";
import ClaimNft from './components/Claim/ClaimNft';
import { useEffect,useState } from 'react';
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Staking from './components/Staking/Staking.js';
function App() {

const [countryyName,setCountryyName]=useState(null)
  const [showservice, setShowService] = useState(false);
  const handleCloseService = () => setShowService(false);
  const handleShowService = () => setShowService(true);
  useEagerConnect();




  const get_ApiKeys = async () => {
    // let ipp=localStorage.getItem('ip')
    try {
      const response = await axios({
        method: "get",
        url: "https://ip.nf/me.json",
      });
      //  console.log(ipp,'response', response?.data.ip.ip);
      // if (!ipp||ipp!=response?.data.ip.ip){
      localStorage.setItem('ip', response?.data.ip.ip)
      get_ApiKeys1(response?.data.ip.ip)
      // }

    } catch (error) {
      setShowService(false)
      console.log(error, 'error');
      // localStorage.setItem('ip',response?.data.ip)
      // localStorage.setItem('country',response?.data.country)
      // throw error; 
    }
  };


  const get_ApiKeys1 = async (e) => {
    try {
      const response = await axios({
        method: "get",
        url: `https://api.iplocation.net/?ip=${e}`,
      });
      //  console.log(response?.data.country_name,'sdsdsdsds',);
      // localStorage.setItem('ip', response?.data.ip.ip)
      localStorage.setItem('country', response?.data.country_name?.toLowerCase())
      // setCountryyName(response?.data.ip.country)
       if     (response?.data.country_name?.toLowerCase()?.includes("southsudan") ||
       response?.data.country_name?.toLowerCase()?.includes("south sudan") ||
       response?.data.country_name?.toLowerCase()?.includes("afghanistan") ||
       response?.data.country_name?.toLowerCase()?.includes("yemen") ||
       response?.data.country_name?.toLowerCase()?.includes("myanmar") ||
       response?.data.country_name?.toLowerCase()?.includes("unitedstates") ||
       response?.data.country_name?.toLowerCase()?.includes("united states") ||
       response?.data.country_name?.toLowerCase()?.includes("iran") ||
       response?.data.country_name?.toLowerCase()?.includes("north korea") ||
       response?.data.country_name?.toLowerCase()?.includes("syria") ||
       response?.data.country_name?.toLowerCase()?.includes("lebanon") ||
       response?.data.country_name?.toLowerCase()?.includes("northkorea") ||
       response?.data.country_name?.toLowerCase()?.includes("america") ||
       response?.data.country_name?.toLowerCase()?.includes("usa")){
      setShowService(true)
    }
      //  }
      else{
      setShowService(false)
      }
  
    } catch (error) {
      setShowService(false)
      localStorage.setItem('country', 'india')
      console.log(error, 'error');
      // localStorage.setItem('ip',response?.data.ip)
      // localStorage.setItem('country',response?.data.country)
      // throw error; 
    }
  };
  useEffect(() => {
    get_ApiKeys()
  }, [])
  return (
    <>
      <img src="\assets\leftimg.png" alt="leftimg" class="leftimg img-fluid"/>
        <img src="\assets\rightimg.png" alt="rightimg" class="rightimg img-fluid"/>
        <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="light" position="top-center" />
        <Router>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/buy' component={Landing} />
            <Route exact path='/claim' component={Claim} />
            <Route exact path='/ClaimNft' component={ClaimNft} />
            <Route exact path='/discount' component={Staking} />
          </Switch>
        </Router>


        <Modal className="service-modal" backdrop="static"
        keyboard={false} show={showservice} onHide={handleCloseService} centered>
        <Modal.Body>
          <div className="upper-div">
            <img src="\ic_round-warning.svg" alt="img" className="img-fluid" />
            <h6>Service Unavailable</h6>
          </div>
          <div className="bottom-div">
            <h6>The Service is Unavailable in Your Region</h6>
            <p>We're sorry, but the service you are trying to access is not available in {countryyName}.</p>
          </div>
        </Modal.Body>
      </Modal>
        
      </>
      );
}

      export default App;
